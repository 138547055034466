import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { RootState } from 'app/store/rootReducer';
import {
  FlexCode,
  FlexCodeImport,
  FlexCodeImportByPaidolIdQuery,
  FlexCodesByFlexCodeImportIdQuery,
  FlexCostCode,
  FlexCostCodesByFlexCodeImportIdQuery,
  FlexCostType,
  FlexCostTypesByFlexCodeImportIdQuery,
} from 'API';
import {
  flexCodeImportByPaidolId,
  flexCodesByFlexCodeImportId,
  flexCostCodesByFlexCodeImportId,
  flexCostTypesByFlexCodeImportId,
} from 'graphql/queries';

export interface FlexCodingState {
  loading: boolean;
  flexCodeImportFile: FlexCodeImport | undefined;
  projects: FlexCode[] | undefined;
  costCodes: FlexCostCode[] | undefined;
  costTypes: FlexCostType[] | undefined;
}

export const initialState: FlexCodingState = {
  loading: false,
  flexCodeImportFile: undefined,
  projects: undefined,
  costCodes: undefined,
  costTypes: undefined,
};

export const getMostRecentFlexCodeImportFile = createAsyncThunk(
  'flexCoding/getMostRecentFlexCodeImportFile',
  async (paidolId: string) => {
    return (
      API.graphql(
        graphqlOperation(flexCodeImportByPaidolId, {
          paidolId,
          sortDirection: 'DESC',
          filter: {
            status: { eq: 'COMPLETED' },
          },
        })
      ) as Promise<GraphQLResult<FlexCodeImportByPaidolIdQuery>>
    ).then((results) => results?.data?.flexCodeImportByPaidolId?.items?.[0]);
  }
);

export const listFlexCodes = createAsyncThunk(
  'flexCoding/listFlexCodes',
  async (flexCodeImportId: string) => {
    return (
      API.graphql(
        graphqlOperation(flexCodesByFlexCodeImportId, {
          filter: {
            isActive: { eq: true },
          },
          flexCodeImportId,
          limit: 10000,
          sortDirection: 'ASC',
        })
      ) as Promise<GraphQLResult<FlexCodesByFlexCodeImportIdQuery>>
    ).then((results) => results?.data?.flexCodesByFlexCodeImportId?.items || []);
  }
);

export const listFlexCostCodes = createAsyncThunk(
  'flexCoding/listFlexCostCodes',
  async (flexCodeImportId: string) => {
    return (
      API.graphql(
        graphqlOperation(flexCostCodesByFlexCodeImportId, {
          flexCodeImportId,
          limit: 10000,
          sortDirection: 'ASC',
        })
      ) as Promise<GraphQLResult<FlexCostCodesByFlexCodeImportIdQuery>>
    ).then((results) => results?.data?.flexCostCodesByFlexCodeImportId?.items || []);
  }
);

export const listFlexCostTypes = createAsyncThunk(
  'flexCoding/listFlexCostTypes',
  async (flexCodeImportId: string) => {
    return (
      API.graphql(
        graphqlOperation(flexCostTypesByFlexCodeImportId, {
          flexCodeImportId,
          limit: 10000,
          sortDirection: 'ASC',
        })
      ) as Promise<GraphQLResult<FlexCostTypesByFlexCodeImportIdQuery>>
    ).then((results) => results?.data?.flexCostTypesByFlexCodeImportId?.items || []);
  }
);

const flexCodingSlice = createSlice({
  name: 'flexCoding',
  initialState,
  reducers: {
    resetFlexCodingSlice: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMostRecentFlexCodeImportFile.fulfilled, (state, action) => {
      state.loading = false;
      state.flexCodeImportFile = action.payload as FlexCodeImport;
    });
    builder.addCase(listFlexCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.projects = action.payload as FlexCode[];
    });
    builder.addCase(listFlexCostCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.costCodes = action.payload as FlexCostCode[];
    });
    builder.addCase(listFlexCostTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.costTypes = action.payload as FlexCostType[];
    });
    builder.addMatcher(
      isAnyOf(
        listFlexCodes.pending,
        listFlexCostCodes.pending,
        listFlexCostTypes.pending,
        getMostRecentFlexCodeImportFile.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        listFlexCodes.rejected,
        listFlexCostCodes.rejected,
        listFlexCostTypes.rejected,
        getMostRecentFlexCodeImportFile.rejected
      ),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { resetFlexCodingSlice } = flexCodingSlice.actions;

export const selectFlexCodingSlice = (state: RootState): FlexCodingState => state.flexCoding || initialState;

export default flexCodingSlice.reducer;
