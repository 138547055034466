import { Box } from '@mui/system';
import AppHeader from 'app/shared-components/layout/AppHeader';
import Loading from 'app/shared-components/util/Loading';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import NavBar from './navbar/NavBar';
import { useFetchProcoreCommonData } from 'app/hooks/useFetchProcoreCommonData';
import { useFetchAgaveCommonData } from 'app/hooks/useFetchAgaveCommonData';
import { API, graphqlOperation } from 'aws-amplify';
import {
  v2CardHealthCheck,
  v2InsightsHealthCheck,
  v2TransactionHealthCheck,
  v2UserHealthCheck,
} from 'graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';

function Layout(): JSX.Element {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');

  const location = useLocation();
  const isReviewPage = location.pathname.match(/^\/review/);

  useFetchProcoreCommonData(codeParam);
  useFetchAgaveCommonData();

  const warmUp = async () => {
    await Promise.all([
      API.graphql(
        graphqlOperation(v2CardHealthCheck, {
          input: new Date().toISOString(),
        })
      ) as Promise<GraphQLResult<string>>,
      API.graphql(
        graphqlOperation(v2InsightsHealthCheck, {
          input: new Date().toISOString(),
        })
      ) as Promise<GraphQLResult<string>>,
      API.graphql(
        graphqlOperation(v2TransactionHealthCheck, {
          input: new Date().toISOString(),
        })
      ) as Promise<GraphQLResult<string>>,
      API.graphql(
        graphqlOperation(v2UserHealthCheck, {
          input: new Date().toISOString(),
        })
      ) as Promise<GraphQLResult<string>>,
    ]);
  };

  useEffect(() => {
    warmUp();
  }, []);

  return (
    <>
      <AppHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        {!isReviewPage && <NavBar />}
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
}

export default Layout;
