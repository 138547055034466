import {
  CardGroup,
  HNPaymentCardConnection,
  HNUSBusinessAuthorizedPerson,
  HNUSBusinessProfile,
  MerchantSpendRule,
} from 'API';

export type HnGetPaymentCardsForBusinessIdQuery = {
  hnGetPaymentCardsForBusinessId?: {
    __typename: 'HNUSBusinessAccountHolder';
    id: string;
    primaryAuthorizedPerson?: HNUSBusinessAuthorizedPerson;
    businessProfile?: HNUSBusinessProfile;
    paymentCards?: HNPaymentCardConnection;
    externalId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export const hnGetPaymentCardsForBusinessId = /* GraphQL */ `
  query hnGetPaymentCardsForBusinessId(
    $id: ID!
    $first: Int = 20
    $after: String
    $filterBy: HNAccountHolderPaymentCardsFilterInput
  ) {
    hnGetPaymentCardsForBusinessId(id: $id, first: $first, after: $after, filterBy: $filterBy) {
      paymentCards {
        edges {
          cursor
          node {
            id
            last4
            network
            status
            expirationDate
            cardProduct {
              name
            }
            formFactor
            attachedSpendRules {
              edges {
                cursor
                node {
                  __typename
                  id
                  name
                  ... on HNAmountLimitSpendRule {
                    maximumAmount {
                      currencyCode
                      value
                    }
                  }
                  ... on HNMerchantCategorySpendRule {
                    merchantCategoryAllowed: allowed
                    merchantCategoryBlocked: blocked
                  }
                  ... on HNMerchantCountrySpendRule {
                    merchantCountryAllowed: allowed
                    merchantCountryBlocked: blocked
                  }
                  ... on HNCVVSpendRule {
                    CVVAllowed: allowed
                    CVVBlocked: blocked
                  }
                  ... on HNStreetAddressSpendRule {
                    streetAddressAllowed: allowed
                    streetAddressBlocked: blocked
                  }
                }
              }
            }
            attachedVelocityRules {
              edges {
                cursor
                node {
                  ... on HNVelocityRule {
                    id
                    cumulativeRule {
                      __typename
                      id
                      name
                      ... on HNAmountLimitSpendRule {
                        maximumAmount {
                          currencyCode
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export const hnGetAccountHolderCardProductApplication = /* GraphQL */ `
  query HnGetAccountHolderCardProductApplication($id: ID!, $firstApplicationHistory: Int = 20) {
    hnGetAccountHolderCardProductApplication(id: $id) {
      __typename
      id
      createdAt
      updatedAt
      applicationState {
        status
      }
      cardProduct {
        name
      }
      adverseActionReasons {
        code
        description
      }
      accountHolderSnapshot {
        ... on HNUSPersonAccountHolderSnapshot {
          __typename
          name {
            givenName
            familyName
            title
            suffix
          }
          currentVerification {
            status
            reason
            results {
              code
              description
            }
            requiredDocuments {
              __typename
              createdAt
              referenceIdentifier
              updatedAt
              status
              documentUploadSession {
                ... on HNUSAccountHolderApplicationDocumentUploadSession {
                  status
                  id
                  primaryDocumentTypes
                  secondaryDocumentTypes
                  uploadRequirements {
                    constraints {
                      ... on HNDocumentUploadRequirementConstraint {
                        numberOfDocuments
                        documentType
                      }
                    }
                  }
                }
              }
              uploadedDocuments {
                ... on HNAccountHolderApplicationDocument {
                  status
                  type
                  createdAt
                  updatedAt
                }
              }
            }
          }
        }
        ... on HNUSBusinessAccountHolderSnapshot {
          __typename
          primaryAuthorizedPerson {
            name {
              givenName
              familyName
              title
              suffix
            }
            currentVerification {
              status
              reason
              results {
                code
                description
              }
              requiredDocuments {
                __typename
                createdAt
                referenceIdentifier
                updatedAt
                status
                documentUploadSession {
                  ... on HNUSAccountHolderApplicationDocumentUploadSession {
                    status
                    id
                    primaryDocumentTypes
                    secondaryDocumentTypes
                    uploadRequirements {
                      constraints {
                        ... on HNDocumentUploadRequirementConstraint {
                          numberOfDocuments
                          documentType
                        }
                      }
                    }
                  }
                }
                uploadedDocuments {
                  ... on HNAccountHolderApplicationDocument {
                    status
                    type
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
          businessProfile {
            name {
              doingBusinessAsName
              legalBusinessName
            }
            currentCreditUnderwritingVerification {
              status
              decision {
                ... on HNUnderwriterPositiveDecision {
                  creditLine {
                    value
                    currencyCode
                  }
                }
                ... on HNUnderwriterAdverseDecision {
                  adverseActionReasons {
                    code
                    description
                  }
                }
              }
            }
            currentVerification {
              status
              reason
              results {
                code
                description
              }
              requiredDocuments {
                __typename
                createdAt
                referenceIdentifier
                updatedAt
                status
                documentUploadSession {
                  ... on HNUSAccountHolderApplicationDocumentUploadSession {
                    status
                    id
                    primaryDocumentTypes
                    secondaryDocumentTypes
                    uploadRequirements {
                      constraints {
                        ... on HNDocumentUploadRequirementConstraint {
                          numberOfDocuments
                          documentType
                        }
                      }
                    }
                  }
                }
                uploadedDocuments {
                  ... on HNAccountHolderApplicationDocument {
                    status
                    type
                    createdAt
                    updatedAt
                  }
                }
              }
            }
            ultimateBeneficialOwners {
              name {
                givenName
                familyName
                title
                suffix
              }
              currentVerification {
                status
                reason
                results {
                  code
                  description
                }
                requiredDocuments {
                  __typename
                  createdAt
                  referenceIdentifier
                  updatedAt
                  status
                  documentUploadSession {
                    ... on HNUSAccountHolderApplicationDocumentUploadSession {
                      status
                      id
                      primaryDocumentTypes
                      secondaryDocumentTypes
                      uploadRequirements {
                        constraints {
                          ... on HNDocumentUploadRequirementConstraint {
                            numberOfDocuments
                            documentType
                          }
                        }
                      }
                    }
                  }
                  uploadedDocuments {
                    ... on HNAccountHolderApplicationDocument {
                      status
                      type
                      createdAt
                      updatedAt
                    }
                  }
                }
              }
            }
          }
        }
      }
      applicationHistory(first: $firstApplicationHistory) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ... on HNAccountHolderCardProductApplicationSnapshot {
              __typename
              createdAt
              updatedAt
              applicationState {
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const hnGetFinancialAccount = /* GraphQL */ `
  query HnGetBusinessAccountHolder($id: String!) {
    hnGetBusinessAccountHolder(id: $id) {
      id
      financialAccounts {
        edges {
          node {
            __typename
            id
            ... on HNFinancialAccount {
              id
              accountStatus
              directDepositDetails {
                id
              }
              activeBillingCycleConfiguration {
                paymentDueDayOfMonth
                billingCycleStartDayOfMonth
                billingCycleEndDayOfMonth
                from
                through
              }
              ledgers {
                name
                normalBalance
                debitBalance {
                  value
                  currencyCode
                }
                creditBalance {
                  value
                  currencyCode
                }
              }
            }
          }
        }
      }
      externalFinancialAccounts {
        edges {
          node {
            __typename
            ... on HNExternalFinancialBankAccount {
              id
              name
              accountStatus
              externalBankAccountDetails {
                __typename
                routingNumber
                last4
                id
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const getPaidolFinancialAccount = /* GraphQL */ `
  query PaidolUserByUserId(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaidolUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paidolUserByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paidol {
          bankAccounts {
            accountNumber
            accountType
            routingNumber
            bankId
          }
        }
      }
    }
  }
`;

export const CreateHighnotePaymentCard = /* GraphQL */ `
  mutation CreateHighnotePaymentCard(
    $input: CreateHighnotePaymentCardInput!
    $condition: ModelHighnotePaymentCardConditionInput
  ) {
    createHighnotePaymentCard(input: $input, condition: $condition) {
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      name
      group {
        name
      }
      paidolUsers(limit: 100) {
        items {
          id
          paidolUser {
            email
            user {
              id
              username
              email
              first_name
              last_name
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GetHighnotePaymentCard = /* GraphQL */ `
  query GetHighnotePaymentCard($paymentCardId: ID!) {
    getHighnotePaymentCard(paymentCardId: $paymentCardId) {
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      name
      last4
      formFactor
      status
      hasPin
      group {
        id
        name
      }
      paidolUsers(limit: 100) {
        items {
          id
          paidolUser {
            email
            user {
              id
              username
              email
              first_name
              last_name
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const ListHighnotePaymentCards = /* GraphQL */ `
  query ListHighnotePaymentCards($paidolId: ID!) {
    listHighnotePaymentCardsByPaidolId(paidolId: $paidolId, limit: 100) {
      nextToken
      items {
        paymentCardId
        paidolId
        authPaidolId
        cardGroupId
        name
        group {
          name
        }
        paidolUsers(limit: 100) {
          items {
            id
            paidolUser {
              email
              user {
                id
                username
                email
                first_name
                last_name
              }
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SearchHighnotePaymentCards = /* GraphQL */ `
  query SearchHighnotePaymentCards(
    $filter: SearchableHighnotePaymentCardFilterInput
    $sort: [SearchableHighnotePaymentCardSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHighnotePaymentCardAggregationInput]
  ) {
    searchHighnotePaymentCards(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        paidolUsers {
          items {
            paidolUser {
              email
              user {
                first_name
                last_name
                email
              }
            }
          }
        }
        cardGroupId
        paymentCardId
        last4
        name
      }
    }
  }
`;

export const UpdateHighnotePaymentCard = /* GraphQL */ `
  mutation UpdateHighnotePaymentCard(
    $input: UpdateHighnotePaymentCardInput!
    $condition: ModelHighnotePaymentCardConditionInput
  ) {
    updateHighnotePaymentCard(input: $input, condition: $condition) {
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      name
      hasPin
      group {
        name
      }
      paidolUsers(limit: 100) {
        items {
          paidolUser {
            email
            user {
              id
              username
              first_name
              last_name
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export type GetGroupsForPaidolIdQuery = {
  listCardGroupsByPaidolId?: {
    __typename: 'ModelCardGroupConnection';
    items: Array<CardGroup>;
    nextToken?: string | null;
  } | null;
};

export const GetGroupsForPaidolId = /* GraphQL */ `
  query GetGroupsForPaidolId($paidolId: ID!, $nextToken: String, $limit: Int = 100) {
    listCardGroupsByPaidolId(
      paidolId: $paidolId
      nextToken: $nextToken
      filter: { deletedAt: { attributeExists: false } }
    ) {
      nextToken
      items {
        id
        name
        spendGroupId
        authPaidolId
        isMonthlyBudgetUnlimited
        paymentCards(limit: $limit) {
          items {
            paymentCardId
            paidolUsers {
              items {
                paidolUser {
                  id
                  email
                  user {
                    id
                    first_name
                    last_name
                    picture
                  }
                }
              }
            }
          }
        }
        groupAdmins {
          items {
            paidolUser {
              id
              email
              user {
                id
                first_name
                last_name
                picture
              }
            }
          }
        }
        monthlyBudget {
          currency
          value
        }
        monthlyBudgetSpend(sortDirection: DESC) {
          items {
            amount
            yearAndMonth
          }
        }
      }
    }
  }
`;

export const createCardGroup = /* GraphQL */ `
  mutation CreateCardGroup($input: CreateCardGroupInput!, $condition: ModelCardGroupConditionInput) {
    createCardGroup(input: $input, condition: $condition) {
      id
      name
      isMonthlyBudgetUnlimited
      spendGroupId
      paymentCards(limit: 100) {
        items {
          paymentCardId
        }
      }
      monthlyBudget {
        currency
        value
      }
      monthlyBudgetSpend(limit: 1, sortDirection: DESC) {
        items {
          amount
          yearAndMonth
        }
      }
    }
  }
`;

export const updateCardGroup = /* GraphQL */ `
  mutation UpdateCardGroup($input: UpdateCardGroupInput!, $condition: ModelCardGroupConditionInput) {
    updateCardGroup(input: $input, condition: $condition) {
      id
      name
      isMonthlyBudgetUnlimited
      paymentCards(limit: 100) {
        items {
          paymentCardId
        }
      }
      monthlyBudget {
        currency
        value
      }
      monthlyBudgetSpend(limit: 1, sortDirection: DESC) {
        items {
          amount
          yearAndMonth
        }
      }
    }
  }
`;

export const removeCardGroup = /* GraphQL */ `
  mutation RemoveCardGroup($input: RemoveCardGroupInput!) {
    removeCardGroup(input: $input) {
      id
    }
  }
`;

export type GetCardGroupQuery = {
  getCardGroup?: CardGroup | null;
};

export const SearchHighnoteTransactions = /* GraphQL */ `
  query SearchSpxTransactions($input: SearchSpxTransactionsInput!) {
    searchSpxTransactions(input: $input) {
      items {
        transactionId
        userId
        user {
          id
          username
          email
          first_name
          last_name
        }
        email
        approvedAmount {
          value
          currencyCode
        }
        authPaidolId
        cardGroupId
        createdAt
        merchantCategory
        merchantName
        paidolId
        paymentCardId
        status
        note
        jobCodeId
        jobCode {
          id
          code
          description
          budget
          isActive
          jobCodeImportId
          paidolId
          authPaidolId
          createdAt
          updatedAt
        }
        procoreCompanyId
        procoreCompany {
          id
          name
        }
        procoreProjectId
        procoreProject {
          id
          name
          display_name
        }
        procoreWBSCodeId
        procoreWBSCode {
          id
          description
        }
        procoreDirectCostId
        procoreDirectCostLineItemId
        agaveCostCode {
          id
          name
          source_id
        }
        agaveCostCodeId
        agaveCostType {
          id
          name
          source_id
        }
        agaveCostTypeId
        agaveProject {
          id
          name
          source_id
        }
        agaveGlCodeId
        agaveGlCode {
          id
          name
        }
        agaveVendor {
          id
          name
        }
        agaveExpense {
          id
          batch
          description
          source_id
        }
        agaveExpenseId
        agaveVendorId
        agaveProjectId
        transactionDate
        receiptPath
        updatedAt
        flexCodeId
        flexCode {
          id
          projectName
          projectCode
        }
        flexCostCodeId
        flexCostCode {
          id
          code
        }
        flexCostTypeId
        flexCostType {
          id
          name
        }
        selectedCodingOption
        receiptPath
        flexCodeTransactionStatus
        approveWithoutReceipts
        approveWithoutNotes
        erpApprovalStatus
      }
      nextToken
      total
    }
  }
`;

export type GetMerchantSpendRulesForPaidolIdQuery = {
  listMerchantSpendRulesByPaidolId?: {
    __typename: 'ModelMerchantSpendRuleConnection';
    items: Array<MerchantSpendRule>;
    nextToken?: string | null;
  } | null;
};

export const GetMerchantSpendRulesForPaidolId = /* GraphQL */ `
  query GetMerchantSpendRulesForPaidolId($paidolId: ID!, $nextToken: String, $limit: Int = 100) {
    listMerchantSpendRulesByPaidolId(paidolId: $paidolId, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        authPaidolId
        merchantCategorySpendRuleId
        merchantCountrySpendRuleId
        amountLimitSpendRuleId
        monthlyLimitSpendRuleId
        paidolId
        updatedAt
        name
        id
        createdAt
        paymentCards
      }
    }
  }
`;

export const GetPaymentCardDetails = /* GraphQL */ `
  query HnGetPaymentCardDetails($id: ID!, $paidolId: ID!, $yearAndMonth: String!) {
    hnGetPaymentCardDetails(id: $id) {
      id
      last4
      network
      status
      paymentCardHolderDetails {
        billingAddressOnCard {
          region
          countryCodeAlpha3
          extendedAddress
          locality
          postalCode
          streetAddress
        }
      }
      expirationDate
      cardProduct {
        name
      }
      formFactor
      attachedSpendRules {
        edges {
          cursor
          node {
            __typename
            id
            name
            ... on HNAmountLimitSpendRule {
              maximumAmount {
                currencyCode
                value
              }
            }
            ... on HNMerchantCategorySpendRule {
              merchantCategoryAllowed: allowed
              merchantCategoryBlocked: blocked
            }
            ... on HNMerchantCountrySpendRule {
              merchantCountryAllowed: allowed
              merchantCountryBlocked: blocked
            }
            ... on HNCVVSpendRule {
              CVVAllowed: allowed
              CVVBlocked: blocked
            }
            ... on HNStreetAddressSpendRule {
              streetAddressAllowed: allowed
              streetAddressBlocked: blocked
            }
          }
        }
      }
      attachedVelocityRules {
        edges {
          cursor
          node {
            ... on HNVelocityRule {
              id
              cumulativeRule {
                ... on HNAmountLimitSpendRule {
                  __typename
                  id
                  name
                  maximumAmount {
                    currencyCode
                    value
                  }
                }
              }
            }
          }
        }
      }
      velocitySpendRuleBalances {
        ... on HNVelocitySpendRuleAmountBalance {
          currentBalance {
            currencyCode
            value
          }
          remainingBalance {
            currencyCode
            value
          }
        }
      }
      physicalPaymentCardOrders {
        orderState {
          status
        }
        paymentCardShipment {
          courier {
            method
            signatureRequiredOnDelivery
            tracking {
              trackingNumber
              actualShipDateLocal
            }
          }
          requestedShipDate
        }
        createdAt
        id
      }
    }
    getHighnotePaymentCard(paymentCardId: $id) {
      paymentCardId
      paidolId
      authPaidolId
      cardGroupId
      name
      last4
      formFactor
      status
      hasPin
      group {
        id
        name
      }
      paidolUsers(limit: 100) {
        items {
          id
          paidolUser {
            email
            user {
              id
              username
              email
              picture
              first_name
              last_name
            }
          }
        }
      }
      createdAt
      updatedAt
    }
    getBudgetSpendByMonthAndPaymentCard(
      paidolId: $paidolId
      yearAndMonth: $yearAndMonth
      paymentCardId: $id
    ) {
      paidolId
      authPaidolId
      yearAndMonth
      paymentCardId
      amount
      transactionCount
      createdAt
      updatedAt
    }
  }
`;

export const GetSpendRuleById = /* GraphQL */ `
  query GetSpendRuleById($ruleId: ID!) {
    hnGetSpendRuleById(ruleId: $ruleId) {
      id
      name
      blockedCountry
      allowedCountry
      maximumAmount {
        value
      }
      allowedCategory
      blockedCategory
    }
  }
`;

export const hnViewDirectDepositDetails = /* GraphQL */ `
  query hnViewDirectDepositDetails($id: ID!) {
    hnViewDirectDepositDetails(id: $id) {
      ... on HNDirectDepositDetail {
        id
        restrictedDetails {
          ... on HNDirectDepositDetailRestrictedDetails {
            __typename
            type
            number
            routingNumber
            bank {
              name
              address {
                streetAddress
                postalCode
              }
            }
          }
          ... on HNAccessDeniedError {
            __typename
            message
          }
        }
      }
    }
  }
`;

export const GetSpendRulesConfigByPaidolId = /* GraphQL */ `
  query GetSpendRulesConfigByPaidolId(
    $paidolId: ID!
    $filter: ModelSpendRuleConfigFilterInput
    $nextToken: String
    $limit: Int = 100
  ) {
    listSpendRulesConfigByPaidolId(
      paidolId: $paidolId
      filter: $filter
      nextToken: $nextToken
      limit: $limit
    ) {
      nextToken
      items {
        id
        authPaidolId
        paidolId
        createdAt
        updatedAt
        ruleId
        ruleType
      }
    }
  }
`;

export const hnFindFinancialAccountTransfers = /* GraphQL */ `
  query HnFindFinancialAccountTransfers(
    $id: ID!
    $firstScheduledTransfers: Int = 20
    $scheduledTransfersAfterCursor: String
    $firstIntegratorTransfers: Int = 20
    $integratorTransfersAfterCursor: String
    $firstExternalTransfers: Int = 20
    $externalTransfersAfterCursor: String
  ) {
    hnFindFinancialAccountTransfers(
      id: $id
      firstScheduledTransfers: $firstScheduledTransfers
      scheduledTransfersAfterCursor: $scheduledTransfersAfterCursor
      firstIntegratorTransfers: $firstIntegratorTransfers
      integratorTransfersAfterCursor: $integratorTransfersAfterCursor
      firstExternalTransfers: $firstExternalTransfers
      externalTransfersAfterCursor: $externalTransfersAfterCursor
    ) {
      __typename
      id
      name
      incomingScheduledTransfers(first: $firstScheduledTransfers, after: $scheduledTransfersAfterCursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          __typename
        }
        edges {
          cursor
          __typename
          node {
            id
            __typename
            ... on HNOneTimeACHTransfer {
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              scheduledTransferDate
              status
              transferAmount {
                __typename
                ... on HNManualTransferAmount {
                  amount {
                    value
                    currencyCode
                    __typename
                  }
                  __typename
                }
                ... on HNTransferAmountCalculation {
                  calculatedType
                  __typename
                }
              }
              __typename
            }
            ... on HNRecurringACHTransfer {
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              frequency
              nextScheduledTransferDate
              status
              transferAmount {
                __typename
                ... on HNManualTransferAmount {
                  amount {
                    value
                    currencyCode
                    __typename
                  }
                  __typename
                }
                ... on HNTransferAmountCalculation {
                  calculatedType
                  __typename
                }
              }
              __typename
            }
          }
        }
        __typename
      }
      integratorACHTransfers(first: $firstIntegratorTransfers, after: $integratorTransfersAfterCursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          __typename
        }
        edges {
          node {
            __typename
            ... on HNSecureDepositACHTransfer {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureIntegratorInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureIntegratorInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNSecureCardBalanceRepaymentACHTransfer {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureIntegratorInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureIntegratorInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNIntegratorInitiatedFundsWithdrawalACHTransfer {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureIntegratorInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureIntegratorInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNIntegratorInitiatedFundsDepositACHTransfer {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureIntegratorInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureIntegratorInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
          }
          __typename
        }
        __typename
      }
      externalACHTransfers(first: $firstExternalTransfers, after: $externalTransfersAfterCursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          __typename
        }
        edges {
          node {
            __typename
            ... on HNDebitFunds {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureExternallyInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureExternallyInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNCreditFunds {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureExternallyInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureExternallyInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNPayrollTransfer {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureExternallyInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureExternallyInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
            ... on HNSecureDeposit {
              id
              amount {
                value
                currencyCode
                __typename
              }
              companyIdentifier
              companyName
              descriptor {
                companyEntryDescription
                individualIdentificationNumber
                individualName
              }
              settlementDate
              status {
                ... on HNNonFailureExternallyInitiatedACHStatus {
                  status
                  __typename
                }
                ... on HNFailureExternallyInitiatedACHStatus {
                  status
                  statusReasonCode
                  __typename
                }
                __typename
              }
              __typename
            }
          }
          __typename
        }
        __typename
      }
    }
  }
`;

export const hnFindFinancialAccountLedgerActivity = /* GraphQL */ `
  query HnFindFinancialAccountLedgerActivity(
    $id: ID!
    $firstActivities: Int = 20
    $activitiesAfterCursor: String
  ) {
    hnFindFinancialAccountLedgerActivity(
      id: $id
      firstActivities: $firstActivities
      activitiesAfterCursor: $activitiesAfterCursor
    ) {
      __typename
      id
      ledgers(ledgerNames: [CASH, OUTSTANDING_BALANCE_PAYABLE]) {
        id
        normalBalance
        asOf
        name
        creditBalance {
          value
          currencyCode
          __typename
        }
        debitBalance {
          value
          currencyCode
          __typename
        }
        ledgerEntries(first: $firstActivities, after: $activitiesAfterCursor) {
          ... on HNLedgerEntryConnection {
            pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
              __typename
            }
            edges {
              cursor
              __typename
              node {
                __typename
                id
                memo
                createdAt
                creditBalanceAtLedgerEntry {
                  value
                  currencyCode
                  __typename
                }
                debitBalanceAtLedgerEntry {
                  value
                  currencyCode
                  __typename
                }
                amount {
                  value
                  currencyCode
                  __typename
                }
                financialEvent {
                  __typename
                  ... on HNNode {
                    id
                    __typename
                  }
                  ... on HNAuthorizationAndClearEvent {
                    merchantDetails {
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNAuthorizationEvent {
                    merchantDetails {
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNClearingEvent {
                    merchantDetails {
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNIssuerPreliminaryAuthorizationEvent {
                    merchantDetails {
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNReversalEvent {
                    merchantDetails {
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNCreditFundsACHTransferEvent {
                    externallyInitiatedACHStatus: status {
                      ... on HNNonFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNDebitFundsACHTransferEvent {
                    externallyInitiatedACHStatus: status {
                      ... on HNNonFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    fromFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNSecureDepositTransferEvent {
                    externallyInitiatedACHStatus: status {
                      ... on HNNonFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNPayrollACHTransferEvent {
                    externallyInitiatedACHStatus: status {
                      ... on HNNonFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureExternallyInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNIntegratorInitiatedFundsDepositACHTransferEvent {
                    integratorInitiatedACHStatus: status {
                      ... on HNNonFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNIntegratorInitiatedFundsWithdrawalACHTransferEvent {
                    integratorInitiatedACHStatus: status {
                      ... on HNNonFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    fromFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNSecureDepositACHTransferEvent {
                    integratorInitiatedACHStatus: status {
                      ... on HNNonFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNSecureCardBalanceRepaymentACHTransferEvent {
                    integratorInitiatedACHStatus: status {
                      ... on HNNonFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      ... on HNFailureIntegratorInitiatedACHStatus {
                        status
                        __typename
                      }
                      __typename
                    }
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    __typename
                  }
                  ... on HNInterFinancialAccountTransfer {
                    fromFinancialAccount {
                      id
                      name
                      __typename
                    }
                    purpose
                    toFinancialAccount {
                      id
                      name
                      __typename
                    }
                    transferActivityType
                    __typename
                  }
                  ... on HNFeeTransferEvent {
                    feeTransfer {
                      __typename
                      ... on HNChargedFeeTransfer {
                        feeActivityType
                        __typename
                      }
                      ... on HNReversedFeeTransfer {
                        sourceFeeTransferEvent {
                          feeTransfer {
                            __typename
                            ... on HNChargedFeeTransfer {
                              feeActivityType
                              __typename
                            }
                          }
                          __typename
                        }
                        __typename
                      }
                    }
                    __typename
                  }
                  ... on HNManualAdjustmentEvent {
                    adjustmentActivityType
                    __typename
                  }
                }
              }
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
`;

export const hnFindFinancialAccountActivities = /* GraphQL */ `
  query HnFindFinancialAccountActivities(
    $id: ID!
    $firstActivities: Int = 20
    $filterBy: HNFinancialAccountActivitiesFilterInput
    $activitiesAfterCursor: String
  ) {
    hnFindFinancialAccountActivities(
      id: $id
      firstActivities: $firstActivities
      filterBy: $filterBy
      activitiesAfterCursor: $activitiesAfterCursor
    ) {
      __typename
      id
      financialAccountActivities(
        first: $firstActivities
        filterBy: $filterBy
        after: $activitiesAfterCursor
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          __typename
        }
        edges {
          cursor
          node {
            __typename
            sign
            isComplete
            pendingAmount {
              value
              currencyCode
              __typename
            }
            postedAmount {
              value
              currencyCode
              __typename
            }
            createdAt
            source {
              __typename
              ... on HNDebitTransaction {
                __typename
                id
                transactionEvents {
                  __typename
                  ... on HNNode {
                    id
                    __typename
                  }
                  ... on HNAuthorizationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNIssuerPreliminaryAuthorizationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNClearingEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    disputes {
                      id
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNBalanceInquiryEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNVerificationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNAuthorizationAndClearEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    disputes {
                      id
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNReversalEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                }
              }
              ... on HNCreditTransaction {
                __typename
                id
                transactionEvents {
                  __typename
                  ... on HNNode {
                    id
                    __typename
                  }
                  ... on HNAuthorizationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNIssuerPreliminaryAuthorizationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNClearingEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    disputes {
                      id
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNBalanceInquiryEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNVerificationEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNAuthorizationAndClearEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    disputes {
                      id
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                  ... on HNReversalEvent {
                    paymentCard {
                      id
                      last4
                      __typename
                    }
                    merchantDetails {
                      category
                      name
                      __typename
                    }
                    pointOfServiceDetails {
                      isCardPresent
                      __typename
                    }
                    __typename
                  }
                }
              }
              ... on HNFeeTransferEvent {
                id
                feeTransfer {
                  __typename
                  ... on HNChargedFeeTransfer {
                    cardProduct {
                      id
                      name
                      __typename
                    }
                    feeActivityType
                    feeAmount {
                      value
                      currencyCode
                      __typename
                    }
                    feeChargeActvity {
                      __typename
                      ... on HNPhysicalPaymentCardOrder {
                        paymentCard {
                          id
                          __typename
                        }
                        __typename
                      }
                      ... on HNPaymentCard {
                        id
                        __typename
                      }
                      ... on HNFinancialAccount {
                        id
                        __typename
                      }
                      ... on HNSecureDepositACHTransfer {
                        id
                        __typename
                      }
                      ... on HNSecureCardBalanceRepaymentACHTransfer {
                        id
                        __typename
                      }
                      ... on HNIntegratorInitiatedFundsWithdrawalACHTransfer {
                        id
                        __typename
                      }
                      ... on HNIntegratorInitiatedFundsDepositACHTransfer {
                        id
                        __typename
                      }
                      ... on HNAuthorizationAndClearEvent {
                        id
                        pointOfServiceDetails {
                          isCardPresent
                          __typename
                        }
                        __typename
                      }
                      ... on HNClearingEvent {
                        id
                        pointOfServiceDetails {
                          isCardPresent
                          __typename
                        }
                        __typename
                      }
                      ... on HNBalanceInquiryEvent {
                        id
                        pointOfServiceDetails {
                          isCardPresent
                          __typename
                        }
                        __typename
                      }
                    }
                    feeChargeRule {
                      name
                      __typename
                    }
                    originatingAccount {
                      id
                      name
                      __typename
                    }
                    receivingAccount {
                      id
                      name
                      __typename
                    }
                    status {
                      __typename
                      ... on HNNonFailureFeeTransferStatus {
                        status
                        __typename
                      }
                      ... on HNFailureFeeTransferStatus {
                        status
                        reasonDetails {
                          reason
                          type
                          __typename
                        }
                        __typename
                      }
                    }
                    transferDate
                    transferedFeeAmount {
                      value
                      currencyCode
                      __typename
                    }
                    __typename
                  }
                  ... on HNReversedFeeTransfer {
                    originatingAccount {
                      id
                      name
                      __typename
                    }
                    receivingAccount {
                      id
                      name
                      __typename
                    }
                    sourceFeeTransferEvent {
                      id
                      feeTransfer {
                        __typename
                        ... on HNChargedFeeTransfer {
                          feeActivityType
                          feeChargeActvity {
                            __typename
                            ... on HNPhysicalPaymentCardOrder {
                              paymentCard {
                                id
                                __typename
                              }
                              __typename
                            }
                            ... on HNPaymentCard {
                              id
                              __typename
                            }
                            ... on HNFinancialAccount {
                              id
                              __typename
                            }
                            ... on HNSecureDepositACHTransfer {
                              id
                              __typename
                            }
                            ... on HNSecureCardBalanceRepaymentACHTransfer {
                              id
                              __typename
                            }
                            ... on HNIntegratorInitiatedFundsWithdrawalACHTransfer {
                              id
                              __typename
                            }
                            ... on HNIntegratorInitiatedFundsDepositACHTransfer {
                              id
                              __typename
                            }
                            ... on HNAuthorizationAndClearEvent {
                              id
                              pointOfServiceDetails {
                                isCardPresent
                                __typename
                              }
                              __typename
                            }
                            ... on HNClearingEvent {
                              id
                              pointOfServiceDetails {
                                isCardPresent
                                __typename
                              }
                              __typename
                            }
                            ... on HNBalanceInquiryEvent {
                              id
                              pointOfServiceDetails {
                                isCardPresent
                                __typename
                              }
                              __typename
                            }
                          }
                          __typename
                        }
                      }
                      __typename
                    }
                    status {
                      __typename
                      ... on HNNonFailureFeeTransferStatus {
                        status
                        __typename
                      }
                      ... on HNFailureFeeTransferStatus {
                        status
                        reasonDetails {
                          reason
                          type
                          __typename
                        }
                        __typename
                      }
                    }
                    transferDate
                    transferedFeeAmount {
                      value
                      currencyCode
                      __typename
                    }
                    __typename
                  }
                }
                __typename
              }
              ... on HNInterFinancialAccountTransfer {
                id
                fromFinancialAccount {
                  id
                  name
                  __typename
                }
                toFinancialAccount {
                  id
                  name
                  __typename
                }
                transferActivityType
                __typename
              }
              ... on HNCreditFunds {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                toFinancialAccount {
                  id
                  name
                  __typename
                }
                createdAt
                settlementDate
                __typename
              }
              ... on HNDebitFunds {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                fromFinancialAccount {
                  id
                  name
                  __typename
                }
                createdAt
                settlementDate
                __typename
              }
              ... on HNSecureDeposit {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                toFinancialAccount {
                  __typename
                  ... on HNFinancialAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNExternalFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNNonVerifiedExternalUSFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                }
                createdAt
                settlementDate
                __typename
              }
              ... on HNPayrollTransfer {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                toFinancialAccount {
                  id
                  name
                  __typename
                }
                createdAt
                settlementDate
                __typename
              }
              ... on HNSecureDepositACHTransfer {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                createdAt
                settlementDate
                toFinancialAccount {
                  __typename
                  ... on HNFinancialAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNExternalFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNNonVerifiedExternalUSFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                }
                __typename
              }
              ... on HNSecureCardBalanceRepaymentACHTransfer {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                createdAt
                settlementDate
                toFinancialAccount {
                  __typename
                  ... on HNFinancialAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNExternalFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNNonVerifiedExternalUSFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                }
                __typename
              }
              ... on HNIntegratorInitiatedFundsWithdrawalACHTransfer {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                createdAt
                settlementDate
                fromFinancialAccount {
                  __typename
                  ... on HNFinancialAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNExternalFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNNonVerifiedExternalUSFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                }
                __typename
              }
              ... on HNIntegratorInitiatedFundsDepositACHTransfer {
                id
                amount {
                  value
                  currencyCode
                  __typename
                }
                companyIdentifier
                companyName
                descriptor {
                  companyEntryDescription
                  individualName
                  __typename
                }
                createdAt
                settlementDate
                toFinancialAccount {
                  __typename
                  ... on HNFinancialAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNExternalFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                  ... on HNNonVerifiedExternalUSFinancialBankAccount {
                    id
                    name
                    __typename
                  }
                }
                __typename
              }
            }
          }
          __typename
        }
        __typename
      }
    }
  }
`;

export const getCards = /* GraphQL */ `
  query GetCards($input: GetCardsInput!) {
    getCards(input: $input) {
      nextToken
      total
      items {
        paymentCardId
        paidolId
        formFactor
        name
        last4
        expirationDate
        status
        procoreEmployee {
          id
          name
          email_address
        }
        cardholder {
          id
          name
          email
          picture
        }
        cardGroup {
          id
          name
        }
        spxSpendRule {
          id
        }
        monthlySpendLimit {
          id
          name
          amount
          currency
          cumulativeRuleId
        }
        maximumTransactionAmount {
          id
          amount
          currency
        }
        spent {
          value
          currencyCode
        }
      }
    }
  }
`;

export const hnGetBusinessAccountHolderDetails = /* GraphQL */ `
  query hnGetBusinessAccountHolderDetails($id: String!) {
    hnGetBusinessAccountHolder(id: $id) {
      ... on HNUSBusinessAccountHolder {
        id
        businessProfile {
          id
          businessType
          name {
            doingBusinessAsName
            legalBusinessName
          }
          billingAddress {
            streetAddress
            extendedAddress
            postalCode
            region
            locality
            countryCodeAlpha3
          }
        }
        primaryAuthorizedPerson {
          id
          email
          authorizingPersonTitle
          dateOfBirth
          percentageOwnership
          name {
            givenName
            familyName
          }
          homeAddress {
            streetAddress
            extendedAddress
            postalCode
            region
            locality
            countryCodeAlpha3
          }
        }
      }
    }
  }
`;
