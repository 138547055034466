import { Box, Typography } from '@mui/material';
import VerticalStyledToggleButtonGroup from 'app/shared-components/toggle-button-group/VerticalStyledToggleButtonGroup';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';

const erpOptions = {
  'sage-100-contractor': 'Sage 100 Contractor',
  'sage-300-cre': 'Sage 300 CRE',
  procore: 'Procore',
  'vista-hosted': 'Vista',
  cmic: 'CMiC',
  'sage-intacct': 'Sage Intacct',
  'quick-books-online': 'QuickBooks Online',
  'quick-books-desktop': 'QuickBooks Desktop',
} as const;

export type ConnectToYourAccountingSystemProps = {
  value?: string;
  setValue: Dispatch<SetStateAction<string | undefined>>;
  disabled?: boolean;
};

function ConnectToYourAccountingSystem({ value, setValue, disabled }: ConnectToYourAccountingSystemProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="1rem"
      flexWrap="wrap"
      width="100%"
      maxWidth="39rem"
    >
      <Typography variant="h4">{t('connectToYourAccountingSystem')}</Typography>
      <VerticalStyledToggleButtonGroup
        options={erpOptions}
        value={value}
        onChange={(_, value) => setValue(value)}
        disabled={disabled}
        fullWidth
      />
    </Box>
  );
}

export default ConnectToYourAccountingSystem;
