import { captureException } from '@sentry/react';
import Loading from 'app/shared-components/util/Loading';
import { useAppDispatch } from 'app/store';
import { signOut } from 'app/store/authSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(signOut())
      .unwrap()
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        captureException(error);
        navigate('/');
      });
  }, [dispatch, navigate]);

  return <Loading />;
}

export default Logout;
