import Login from 'app/pages/auth/Login';
import Error404Page from 'app/pages/misc/Error404Page';
import RequireAuth from 'app/shared-components/auth/RequireAuth';
import Layout from 'app/shared-components/layout/Layout';
import { DEFAULT_LANDING_PAGE } from 'app/store/authSlice';
import { Suspense, lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PurchaseCardsWrapper from './pages/PurchaseCardsWrapper';
import { RequireAdministrator } from './shared-components/auth/RequireUserRole';
import Loading from './shared-components/util/Loading';
import { useIsEnabled } from 'app/shared-components/feature-flags/FeatureFlagProvider';
import ReviewOnboard from './pages/review/ReviewOnboard';
import OnboardReviewNew from './pages/first-run/stepper/OnboardReviewNew';
import LoginSSO from './pages/auth/LoginSSO';
import Logout from './pages/auth/Logout';

const ForgotPasswordPage = lazy(() => import('app/pages/auth/ForgotPasswordPage'));
const Register = lazy(() => import('app/pages/auth/register/Register'));
const VerifyUser = lazy(() => import('app/pages/auth/VerifyUser'));
const Landing = lazy(() => import('app/pages/auth/Landing'));
const Overview = lazy(() => import('./pages/overview/Overview'));
const Style = lazy(() => import('./pages/style/Style'));
const OnBoard = lazy(() => import('./pages/onboard/OnBoard'));
const Team = lazy(() => import('./pages/team/Team'));
const Settings = lazy(() => import('./pages/settings/Settings'));
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage'));
const Wallet = lazy(() => import('./pages/wallet/Wallet'));
const CardsPage = lazy(() => import('./pages/cards/CardsPage'));
const ForgotUsernamePage = lazy(() => import('./pages/auth/ForgotUsernamePage'));
//const Payables = lazy(() => import('./pages/payables/Payables'));

function RoutingTable(): JSX.Element {
  const { 'firstRunStateFlags.isStepperEnabled': isStepperEnabled } = useIsEnabled([
    { featureName: 'firstRunStateFlags', flagName: 'isStepperEnabled' },
  ]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={DEFAULT_LANDING_PAGE} />} />

      <Route
        path="/"
        element={
          <RequireAuth requireUser={false}>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </RequireAuth>
        }
      >
        <Route path="/login" element={<Login />} />
        <Route path="/login/sso" element={<LoginSSO />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/forgot-password/:username" element={<ForgotPasswordPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:email" element={<Register />} />
        <Route path="/verify-user" element={<VerifyUser />} />
        <Route path="/verify-user/:username" element={<VerifyUser />} />
        <Route path="/forgot-username" element={<ForgotUsernamePage />} />

        <Route
          path="/onboard/*"
          element={
            <Suspense fallback={<Loading />}>
              <OnBoard />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/"
        element={
          <RequireAuth>
            <PurchaseCardsWrapper>
              <Layout />
            </PurchaseCardsWrapper>
          </RequireAuth>
        }
      >
        <Route
          path="/review/*"
          element={
            <RequireAdministrator>
              <ReviewOnboard />
            </RequireAdministrator>
          }
        />

        {isStepperEnabled && (
          <Route
            path="/review/onboard"
            element={
              <RequireAdministrator>
                <OnboardReviewNew />
              </RequireAdministrator>
            }
          />
        )}

        <Route
          path="/settings/*"
          element={
            <RequireAdministrator>
              <Settings />
            </RequireAdministrator>
          }
        />
        <Route path="/cards/*" element={<CardsPage />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/overview/*" element={<Overview />} />
        <Route path="/wallet/*" element={<Wallet />} />
        <Route path="/receiveables" element={<></>} />
        <Route path="/team/*" element={<Team />} />
        <Route path="/profile/*" element={<ProfilePage />} />
        <Route path="/style" element={<Style />} />
        <Route
          path="/logout"
          element={
            <RequireAuth>
              <Logout />
            </RequireAuth>
          }
        ></Route>
      </Route>
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default RoutingTable;
