import moment from 'moment';
import AssignGroupModal from 'app/pages/team/group/AssignGroupModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import ResponsiveDialogActions from 'app/shared-components/layout/dialog/ResponsiveDialogActions';
import { PaidolUserToHighnotePaymentCard, Role } from 'API';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/store';
import { DeleteOutline, Lock, LockOpen } from '@mui/icons-material';
import { ReactNode, useState } from 'react';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { triggerGroupUpdate } from 'app/pages/store/groupCardsSlice';
import { resetGroupsSlice } from 'app/pages/store/groupsSlice';
import { LoadingButton } from '@mui/lab';
import { NO_GROUP } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import {
  activatePaymentCard,
  closePaymentCard,
  dispatchEmail,
  dispatchSpeedchatMessage,
  suspendPaymentCard,
  updateHighnotePaymentCard,
} from 'app/pages/store/specificCardSlice';

export enum ModalType {
  lock = 'lock',
  unlock = 'unlock',
  cancel = 'cancel',
  removeFromGroup = 'removeFromGroup',
  moveToAnotherGroup = 'moveToAnotherGroup',
}

interface ModalConfig<T = void> {
  title: string;
  list: ReactNode;
  message: string;
  function: (param: T) => void;
  icon: ReactNode;
  btnText: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ModalTypesConfig = Record<ModalType, ModalConfig<any>>;

export interface ModalActionsCardProps {
  open: boolean;
  type: ModalType;
  cardName: string;
  paymentCardId: string;
  paymentCardUsers?: Array<PaidolUserToHighnotePaymentCard>;
  onClose: () => void;
  onSuccess?: () => void;
}

function ModalActionsCard({
  open,
  type,
  paymentCardUsers,
  cardName,
  paymentCardId,
  onClose,
  onSuccess,
}: ModalActionsCardProps) {
  const dispatch = useAppDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const { user } = useAuth();
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);

  const unlockCard = () => {
    setBtnLoading(true);
    dispatch(
      activatePaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was unlocked on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;

        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const lockCard = () => {
    setBtnLoading(true);
    dispatch(
      suspendPaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was locked on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;

        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const cancelCard = () => {
    setBtnLoading(true);
    dispatch(
      closePaymentCard({
        paymentCardId: paymentCardId,
      })
    ).then(() => {
      if (user?.sub && paymentCardUsers?.length) {
        const message = `${cardName} was canceled on ${moment().format('MMM Do')} by ${user.given_name} ${
          user.family_name
        }.`;
        dispatch(resetGroupsSlice());
        paymentCardUsers.map((paymentCardUser) => {
          if (paymentCardUser?.paidolUser?.user?.id) {
            dispatch(
              dispatchSpeedchatMessage({
                message:
                  paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                    ? `${message} Please contact your administrator for questions about this action or to request it to be unlocked.`
                    : message,
                userId: paymentCardUser?.paidolUser?.user?.id,
                paidolId: selectedPaidolId,
              })
            ).then(() => {
              if (paymentCardUser?.paidolUser?.email) {
                dispatch(
                  dispatchEmail({
                    body: `${message} Log in to your Speedchain app for more info.`,
                    to: paymentCardUser?.paidolUser?.email,
                    subject:
                      paymentCardUser?.paidolUser?.roles === Role.CARDHOLDER
                        ? 'An important update on your Speedchain OneCard'
                        : 'An important update on a Speedchain OneCard in your portfolio',
                  })
                );
              }
            });
          }
        });
      }
      if (onSuccess) {
        onSuccess();
      }
      setBtnLoading(false);
      onClose();
    });
  };

  const moveGroup = async (groupName: string) => {
    setBtnLoading(true);
    try {
      await dispatch(updateHighnotePaymentCard({ paymentCardId, cardGroupId: groupName }));
      await dispatch(resetGroupsSlice());
      await dispatch(triggerGroupUpdate());

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error(
        `Error ${groupName === NO_GROUP ? 'removing card from group' : 'moving card to another group'}:`,
        error
      );
    } finally {
      setBtnLoading(false);
      onClose();
    }
  };

  const moveToAnotherGroup = (groupName: string) => {
    moveGroup(groupName);
  };

  const removeFromGroup = () => {
    moveGroup(NO_GROUP);
  };

  const modalTypes: ModalTypesConfig = {
    [ModalType.lock]: {
      title: 'Lock this card?',
      list: <li>This will immediately block all future transactions</li>,
      message: `Are you sure you wish to ${ModalType.lock} ${cardName}?`,
      function: lockCard,
      icon: <Lock />,
      btnText: 'Lock card',
    },
    [ModalType.unlock]: {
      title: 'Unlock this card?',
      list: <li>This will immediately allow spending to resume on the card</li>,
      message: `Are you sure you wish to ${ModalType.unlock} ${cardName}?`,
      function: unlockCard,
      icon: <LockOpen />,
      btnText: 'Unlock card',
    },
    [ModalType.cancel]: {
      title: 'Cancel this card?',
      list: (
        <>
          <li>This will remove all access to this card</li>
          <li>This cannot be undone</li>
        </>
      ),
      message: `Are you sure you wish to ${ModalType.cancel} ${cardName}?`,
      function: cancelCard,
      icon: <DeleteOutline />,
      btnText: 'Cancel card',
    },
    [ModalType.removeFromGroup]: {
      title: 'Remove from group',
      list: <></>,
      message: `Are you sure you wish to remove ${cardName} from this group?`,
      function: removeFromGroup,
      icon: <DeleteOutline />,
      btnText: 'Remove',
    },
    [ModalType.moveToAnotherGroup]: {
      title: 'Move to another group',
      list: <></>,
      message: `Which group do you want to move this card to?`,
      function: moveToAnotherGroup,
      icon: <ArrowForwardIcon />,
      btnText: 'Move',
    },
  };

  return (
    <>
      {type !== ModalType.moveToAnotherGroup ? (
        <ResponsiveDialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            color="error.main"
            sx={{
              fontSize: '2.4rem',
              mt: '2rem',
              p: '2rem 5rem 1rem',
            }}
          >
            {modalTypes[type].title}
          </DialogTitle>
          <DialogContent sx={{ p: '0 5rem' }}>
            <ul style={{ fontWeight: 'normal', marginTop: '0px' }}>{modalTypes[type].list}</ul>
            <p>{modalTypes[type].message}</p>
          </DialogContent>
          <ResponsiveDialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={btnLoading}
              variant="contained"
              onClick={modalTypes[type].function}
              startIcon={modalTypes[type].icon}
              loadingPosition="start"
              color="error"
            >
              {modalTypes[type].btnText}
            </LoadingButton>
          </ResponsiveDialogActions>
        </ResponsiveDialog>
      ) : (
        <AssignGroupModal
          open={open}
          onClose={onClose}
          loading={btnLoading}
          onClick={modalTypes[type].function}
          icon={modalTypes[type].icon}
          btnText={modalTypes[type].btnText}
        />
      )}
    </>
  );
}

export default ModalActionsCard;
