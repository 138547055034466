import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCardIcon from '@mui/icons-material/AddCard';
import { format } from 'date-fns';
import { Storage } from 'aws-amplify';
import { FormType } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { useSnackbar } from 'notistack';
import { stringToColor } from 'util/helpers';
import { GroupCardType } from './GroupsCard/GroupCard';
import { useTranslation } from 'react-i18next';
import { fetchGroupCards } from 'app/pages/store/groupCardsSlice';
import { captureException } from '@sentry/react';
import { EditGroupFormValues } from './EditGroupForm';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { floatToCurrencyValue } from 'app/shared-components/util/Currency';
import { useCallback, useState } from 'react';
import { resetPaymentCardsSlice } from 'app/pages/store/paymentCardsSlice';
import { useUpdateFirstRunState } from 'app/hooks/useUpdateFirstRunState';
import { MoreVertMenuOptionProps } from 'app/shared-components/MoreVertMenu/MoreVertMenu';
import { EditOutlined as EditIcon } from '@mui/icons-material';
import { addCardsToGroup, getGroup } from 'app/services/groupService';
import { SidesheetRoutes, useSidesheet } from 'app/shared-components/sidesheets/SidesheetProvider';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getMonthlySpendFromMonthlyBudgets } from 'app/shared-components/util/utils';
import {
  addGroupAdmins,
  attachSpendRules,
  getGroups,
  newGroup,
  removeGroup,
  resetGroupsSlice,
  selectGroupsSlice,
  selectSelectedGroup,
  setSelectedGroupId,
  updateGroup,
} from 'app/pages/store/groupsSlice';
import {
  createAmountLimitSpendRule,
  createMerchantCategorySpendRule,
  createMerchantCountrySpendRule,
  createVelocitySpendRule,
  newMerchantSpendRule,
  updateSpendRule,
} from 'app/pages/store/spendRulesSlice';
import {
  CardGroup,
  HNISO4217Alpha3CurrencyCode,
  HNISO4217Alpha3SupportedCurrency,
  HNMerchantCategory,
  HNVelocityRuleWindow,
  MerchantSpendRule,
  PaidolUser,
} from 'API';

export const mapToGroupCard = (
  cardGroups: CardGroup[] | undefined,
  paidolUserPictureMap: Record<string, string>
): GroupCardType[] => {
  const mapped = cardGroups?.map((cardGroup) => {
    const monthlyBudget = cardGroup.monthlyBudget?.value ?? 0;
    const monthlySpend = getMonthlySpendFromMonthlyBudgets(cardGroup.monthlyBudgetSpend?.items);

    // Safely handle flatMap with null/undefined checks
    const paidolUsers =
      cardGroup.paymentCards?.items?.flatMap(
        (paymentCard) =>
          paymentCard?.paidolUsers?.items?.flatMap((paidolUser) =>
            paidolUser?.paidolUser ? [paidolUser.paidolUser] : []
          ) ?? []
      ) ?? ([].filter((value) => value !== undefined) as PaidolUser[]);

    const mapDistinctPaidolUsers: Record<string, PaidolUser> = paidolUsers.reduce((accumulator, value) => {
      return { ...accumulator, [value.id]: value };
    }, {});

    return {
      yearAndMonth: format(new Date(), 'yyyy-MM'),
      id: cardGroup.id,
      name: cardGroup.name,
      cards: cardGroup.paymentCards?.items?.length ?? 0,
      users: Object.entries(mapDistinctPaidolUsers).map(([key, paidolUser]) => {
        let name = paidolUser.user?.first_name;
        if (name && paidolUser.user?.last_name) name += ` ${paidolUser.user?.last_name}`;

        return {
          paidolUserId: key,
          email: paidolUser.email || paidolUser.user?.email || '',
          name,
          picture: paidolUserPictureMap[key],
        };
      }),
      isMonthlyBudgetUnlimited: cardGroup.isMonthlyBudgetUnlimited || false,
      spent: { currency: 'USD', value: monthlySpend },
      budget: cardGroup.isMonthlyBudgetUnlimited ? undefined : { currency: 'USD', value: monthlyBudget },
      groupAdmins:
        cardGroup.groupAdmins?.items
          ?.map((groupAdmin) => groupAdmin?.paidolUser)
          .filter((admin): admin is PaidolUser => admin !== undefined && admin !== null) ?? [],
    };
  });

  return mapped || [];
};

export function stringAvatar(text: string) {
  // split by name or email
  let nameSplit = text.toUpperCase().split(' ');
  if (nameSplit.length < 2) nameSplit = text.toUpperCase().split('@');

  const first = nameSplit[0].length > 0 ? nameSplit[0][0] : undefined;
  const second = nameSplit[1].length > 0 ? nameSplit[1][0] : undefined;

  let children = first;
  if (first && second) children += second;

  return { sx: { bgcolor: stringToColor(text) }, children };
}

export const pluralizeWord = (amount: number, message: string) => {
  const text = amount > 1 ? message + 's' : message;
  return `${amount} ${text}`;
};

export const useCardGroupUtils = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openSidesheet } = useSidesheet();
  const [group, setGroup] = useState<CardGroup | undefined>();
  const [EditCardGroupIsOpen, setEditCardGroupIsOpen] = useState(false);
  const [deleteCardGroupIsOpen, setDeleteCardGroupIsOpen] = useState(false);
  const [paidolUserPictureMap, setPaidolUserPictureMap] = useState<Record<string, string>>({});

  const selectedGroup = useAppSelector(selectSelectedGroup);
  const { nextToken } = useAppSelector(selectGroupsSlice);
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);

  const { enqueueSnackbar } = useSnackbar();

  const { setTrigger } = useUpdateFirstRunState({
    operationName: 'completeFirstRunChecklist',
    firstRunValue: 'createGroup',
  });

  const loadGroup = useCallback(
    async (groupId: string) => {
      try {
        const response = await getGroup({ groupId });
        setGroup(response);
        dispatch(setSelectedGroupId(groupId));
        return response;
      } catch (error) {
        captureException(error);
        enqueueSnackbar(`Error while loading group details!`, {
          variant: 'error',
        });
      }
    },
    [dispatch]
  );

  const loadGroupCards = useCallback(
    (cardGroup: CardGroup) => {
      if (cardGroup) {
        dispatch(fetchGroupCards({ paidolId: selectedPaidolId, cardGroup }));
      }
    },
    [dispatch, selectedPaidolId]
  );

  const initialLoad = useCallback(
    (groupId: string) => {
      if (groupId) {
        loadGroup(groupId).then((cardGroup) => {
          if (cardGroup) {
            loadGroupCards(cardGroup);
          }
        });
      }
    },
    [loadGroup, loadGroupCards]
  );

  const createHighnoteSpendRule = useCallback(
    (
      spendingByCategory: HNMerchantCategory[] | undefined,
      spendingByCountry: HNISO4217Alpha3CurrencyCode[] | undefined,
      name: string,
      spendingByCategoryType: string | undefined,
      spendingByCountryType: string | undefined,
      maximumTransactionAmount: number | undefined,
      monthlySpendLimit: number | undefined
    ) => {
      const dispatchPromises: Promise<any>[] = [];

      const countriesPayload = {
        [spendingByCountryType as string]: spendingByCountry,
      };
      const categoriesPayload = {
        [spendingByCategoryType as string]: spendingByCategory,
      };

      if (countriesPayload) {
        dispatchPromises.push(dispatch(createMerchantCountrySpendRule({ name, ...countriesPayload })));
      }

      if (categoriesPayload) {
        dispatchPromises.push(dispatch(createMerchantCategorySpendRule({ name, ...categoriesPayload })));
      }

      if (maximumTransactionAmount !== undefined && maximumTransactionAmount > 0) {
        dispatchPromises.push(
          dispatch(
            createAmountLimitSpendRule({
              name,
              maximumAmount: {
                value: Math.round(maximumTransactionAmount * 100), // Avoid optional chaining
                currencyCode: HNISO4217Alpha3SupportedCurrency.USD,
              },
            })
          )
        );
      }

      if (monthlySpendLimit !== undefined && monthlySpendLimit > 0) {
        const maximumAmount = {
          value: Math.round(monthlySpendLimit * 100), // Ensures a proper integer value
          currencyCode: HNISO4217Alpha3SupportedCurrency.USD,
        };

        const amountLimitPromise = dispatch(
          createAmountLimitSpendRule({
            name,
            maximumAmount,
          })
        );

        const velocitySpendRulePromise = amountLimitPromise.then((action: any) => {
          if (action?.payload?.id) {
            return dispatch(
              createVelocitySpendRule({
                name,
                velocityRuleWindow: HNVelocityRuleWindow.MONTHLY,
                cumulativeRule: { id: action.payload.id, version: 'LATEST' },
              })
            );
          } else {
            throw new Error('Failed to create amount limit spend rule: Missing payload or ID');
          }
        });

        dispatchPromises.push(velocitySpendRulePromise);
      }

      return Promise.all(dispatchPromises);
    },
    [dispatch]
  );

  const createMerchantSpendRule = useCallback(
    (
      categoryId: string,
      countryId: string,
      amountLimitId: string,
      monthlySpendRuleId: string,
      name: string
    ) =>
      dispatch(
        newMerchantSpendRule({
          createMerchantSpendRuleInput: {
            paidolId: selectedPaidolId,
            authPaidolId: selectedPaidolId,
            name,
            ...(categoryId ? { merchantCategorySpendRuleId: categoryId } : {}),
            ...(amountLimitId ? { amountLimitSpendRuleId: amountLimitId } : {}),
            ...(countryId ? { merchantCountrySpendRuleId: countryId } : {}),
            ...(monthlySpendRuleId ? { monthlyLimitSpendRuleId: monthlySpendRuleId } : {}),
          },
        })
      ).then((result) => result.payload as MerchantSpendRule),
    [dispatch, selectedPaidolId]
  );

  const getGroupsPictureMap = async (_groups: CardGroup[] | undefined = []) => {
    const paidolUsers = _groups
      .flatMap((cardGroup) =>
        cardGroup?.paymentCards?.items.flatMap((paymentCard) =>
          paymentCard?.paidolUsers?.items.flatMap((paidolUser) => paidolUser?.paidolUser)
        )
      )
      .filter((value) => value !== undefined) as PaidolUser[];

    const userPictureMap = await paidolUsers
      .filter((paidolUser) => !!paidolUser.user?.picture)
      .reduce(async (accumulatorPromise, value) => {
        const accumulator = await accumulatorPromise;
        const picture = await Storage.get(value.user!.picture!, {
          level: 'public',
          expires: 86400,
        });

        accumulator[value.id] = picture;
        return accumulator;
      }, Promise.resolve<Record<string, string>>({}));

    return userPictureMap;
  };

  const afterGetGroups = useCallback(async (_groups: CardGroup[] | undefined = []) => {
    const map = await getGroupsPictureMap(_groups);

    setPaidolUserPictureMap(map);
  }, []);

  const loadMore = useCallback(() => {
    if (selectedPaidolId) {
      dispatch(getGroups({ paidolId: selectedPaidolId, nextToken }))
        .unwrap()
        .then((result) => afterGetGroups(result?.items));
    }
  }, [afterGetGroups, dispatch, nextToken, selectedPaidolId]);

  const onSaveGroup = useCallback(
    async ({
      name,
      monthlyBudget,
      isMonthlyBudgetUnlimited,
      cardGroupUsers,
      spendingByCategory,
      spendingByCountry,
      spendingByCategoryType,
      spendingByCountryType,
      maximumTransactionAmount,
      spendRuleSelector,
      merchantSpendRuleId,
      spendRuleGroupName,
      monthlySpendLimit,
      groupAdmins,
      cards,
    }: EditGroupFormValues) => {
      if (!selectedPaidolId) return;

      let groupId = selectedGroup?.id;

      const getMonthlyBudget = () =>
        isMonthlyBudgetUnlimited || monthlyBudget === undefined
          ? null
          : {
              value: floatToCurrencyValue(monthlyBudget),
              currency: 'USD',
            };

      try {
        let action;
        if (spendRuleSelector === 'custom') {
          action = await createHighnoteSpendRule(
            spendingByCategory,
            spendingByCountry,
            name,
            spendingByCategoryType,
            spendingByCountryType,
            maximumTransactionAmount as number | undefined,
            monthlySpendLimit as number | undefined
          );
        }

        let merchantSpendRule: MerchantSpendRule | undefined;
        if (action) {
          const countrySpendRuleId = action.find(
            (action: any) => action.payload?.__typename === 'HNMerchantCountrySpendRule'
          )?.payload?.id;

          const categorySpendRuleId = action.find(
            (action: any) => action.payload?.__typename === 'HNMerchantCategorySpendRule'
          )?.payload?.id;

          const amountLimitSpendRuleId = action.find(
            (action: any) => action.payload?.__typename === 'HNAmountLimitSpendRule'
          )?.payload?.id;

          const monthlySpendRuleId =
            action.find((action: any) => action?.payload?.id?.includes?.('vr_'))?.payload?.id || null;

          merchantSpendRule = await createMerchantSpendRule(
            categorySpendRuleId,
            countrySpendRuleId,
            amountLimitSpendRuleId,
            monthlySpendRuleId,
            name
          );

          await dispatch(
            updateSpendRule({
              updateMerchantSpendRuleInput: { id: merchantSpendRule.id, name: spendRuleGroupName },
            })
          ).unwrap();
        }

        const merchantSpendRuleIdToSave = merchantSpendRule?.id || merchantSpendRuleId;
        const isCreation = !groupId;
        if (groupId) {
          await dispatch(
            updateGroup({
              id: groupId,
              name,
              spendGroupId: merchantSpendRuleIdToSave,
              isMonthlyBudgetUnlimited,
              monthlyBudget: getMonthlyBudget(),
            })
          ).unwrap();
        } else {
          const createdGroup = await dispatch(
            newGroup({
              paidolId: selectedPaidolId,
              authPaidolId: selectedPaidolId,
              spendGroupId: merchantSpendRuleIdToSave,
              name,
              isMonthlyBudgetUnlimited,
              monthlyBudget: getMonthlyBudget(),
            })
          ).unwrap();
          groupId = createdGroup?.id;
          setTrigger(true);
        }

        if (!groupId) throw new Error('Failed to create or update the group.');

        await addCardsToGroup(selectedPaidolId, groupId, cards);

        await dispatch(
          addGroupAdmins({ cardGroupId: groupId, groupAdmins: groupAdmins as PaidolUser[] })
        ).unwrap();
        await dispatch(attachSpendRules({ cardGroupId: groupId }));
        await dispatch(resetPaymentCardsSlice());
        await dispatch(resetGroupsSlice());
        const groups = await dispatch(getGroups({ paidolId: selectedPaidolId })).unwrap();
        await afterGetGroups(groups?.items);
        const message = t(isCreation ? 'groupCreated' : 'groupUpdated');
        setEditCardGroupIsOpen(false);

        enqueueSnackbar(message, {
          variant: 'success',
        });
      } catch (error) {
        console.error('Error in onSaveGroup:', error);
        captureException(error);
      }
    },
    [
      afterGetGroups,
      createHighnoteSpendRule,
      createMerchantSpendRule,
      dispatch,
      enqueueSnackbar,
      selectedGroup?.id,
      selectedPaidolId,
      setTrigger,
      t,
    ]
  );

  const handleClickNewCard = (groupId: string) => {
    dispatch(setSelectedGroupId(groupId));
    openSidesheet(SidesheetRoutes.NewCard, {
      formType: FormType.IssueCard,
    });
  };

  const handleCloseGroupEdit = () => {
    setEditCardGroupIsOpen(false);
  };

  const handleCLoseDeleteGroup = () => {
    setDeleteCardGroupIsOpen(false);
  };

  const handleDeleteCardGroup = async (groupId?: string) => {
    if (!selectedPaidolId || !groupId) return;
    try {
      await dispatch(removeGroup({ paidolId: selectedPaidolId, cardGroupId: groupId })).unwrap();
      await dispatch(resetPaymentCardsSlice());
      await dispatch(resetGroupsSlice());
      const groups = await dispatch(getGroups({ paidolId: selectedPaidolId })).unwrap();
      afterGetGroups(groups?.items);

      enqueueSnackbar(t('groupDeleted.groupDeleted'), {
        variant: 'success',
      });
    } catch (error) {
      console.error('Error deleting card group:', error);
      captureException(error);
    }
  };

  const onNewGroup = useCallback(() => {
    dispatch(setSelectedGroupId(undefined));
    setEditCardGroupIsOpen(true);
  }, [dispatch]);

  const onEditGroup = useCallback(
    (groupId: string) => {
      dispatch(setSelectedGroupId(groupId));
      setEditCardGroupIsOpen(true);
    },
    [dispatch]
  );

  const onDeleteGroup = useCallback(
    (groupId: string) => {
      dispatch(setSelectedGroupId(groupId));
      setDeleteCardGroupIsOpen(true);
    },
    [dispatch]
  );

  const getCardGroupMenu = (groupId: string): MoreVertMenuOptionProps[] => {
    return [
      {
        Icon: <EditIcon color="primary" />,
        label: t('cardGroupActions.editGroup'),
        onClick: () => onEditGroup(groupId),
      },

      {
        Icon: <AddCardIcon color="primary" />,
        label: t('cardGroupActions.createNewCards'),
        onClick: () => handleClickNewCard(groupId),
      },

      {
        Icon: <DeleteOutlineIcon color="primary" />,
        label: t('cardGroupActions.deleteGroup'),
        onClick: () => onDeleteGroup(groupId),
      },

      //TODO: Add new card (need logic to search cards that don't belong to any group)
      /* {
        label: 'Add Existing Card',
        Icon: <EditIcon color="primary" />,
        onClick: () => console.log('Edit Card Group'),
      }, */
    ];
  };

  return {
    getCardGroupMenu,
    handleClickNewCard,
    EditCardGroupIsOpen,
    handleCloseGroupEdit,
    onSaveGroup,
    loadMore,
    afterGetGroups,
    onNewGroup,
    paidolUserPictureMap,
    loadGroup,
    initialLoad,
    loadGroupCards,
    group,
    handleDeleteCardGroup,
    deleteCardGroupIsOpen,
    handleCLoseDeleteGroup,
  };
};
