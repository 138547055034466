import {
  getAccount,
  getGlCodes,
  getPersistedAgaveAccountToken,
  getProjects,
  selectAgaveSlice,
} from 'app/pages/store/agaveSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { useCallback, useEffect, useMemo } from 'react';

export const useFetchAgaveCommonData = () => {
  const dispatch = useAppDispatch();
  const { selectedPaidol } = useAppSelector(selectUserCompanies);
  const { accountToken } = useAppSelector(selectAgaveSlice);

  const agaveAccountToken = useMemo(
    () => selectedPaidol?.agaveAccountToken ?? '',
    [selectedPaidol?.agaveAccountToken]
  );

  const isConstructionType = useMemo(
    () => selectedPaidol?.isConstructionType ?? false,
    [selectedPaidol?.isConstructionType]
  );

  const isAgaveClient = useMemo(
    () => selectedPaidol?.isAgaveClient ?? false,
    [selectedPaidol?.isAgaveClient]
  );

  const loadProjects = useCallback(
    (account_token: string) => {
      return dispatch(getProjects({ input: { account_token } })).unwrap();
    },
    [dispatch]
  );

  const loadGlCodes = useCallback(
    (account_token: string) => {
      return dispatch(getGlCodes({ input: { account_token } })).unwrap();
    },
    [dispatch]
  );

  const getAgaveAccount = useCallback(
    (account_token: string) => {
      return dispatch(getAccount({ input: { account_token } })).unwrap();
    },
    [dispatch]
  );

  useEffect(() => {
    if (isConstructionType && isAgaveClient && selectedPaidol?.id && !accountToken) {
      dispatch(getPersistedAgaveAccountToken(selectedPaidol.id));
    }
  }, [accountToken, agaveAccountToken, dispatch, isAgaveClient, isConstructionType, selectedPaidol?.id]);

  useEffect(() => {
    if (isConstructionType && isAgaveClient && agaveAccountToken) {
      loadProjects(agaveAccountToken);
      loadGlCodes(agaveAccountToken);
      getAgaveAccount(agaveAccountToken);
    }
  }, [
    agaveAccountToken,
    dispatch,
    getAgaveAccount,
    isAgaveClient,
    isConstructionType,
    loadGlCodes,
    loadProjects,
  ]);
};
