import ResponsiveDialogActions from 'app/shared-components/layout/dialog/ResponsiveDialogActions';
import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import { NO_GROUP } from 'app/pages/cards/cards/NewCardSheet/newCardUtils';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { CardGroup, Role } from 'API';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { Groups as GroupsIcon } from '@mui/icons-material';
import { getGroups, selectAllGroups } from 'app/pages/store/groupsSlice';
import { useAppDispatch, useAppSelector } from 'app/store';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

interface AssignGroupModalProps {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onClick: (groupName?: string) => void;
  icon: ReactNode;
  btnText: string;
}

const AssignGroupModal = ({ open, onClose, loading, onClick, icon, btnText }: AssignGroupModalProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { paidolUsers, userRole, selectedPaidolId: selectedCompany } = useAppSelector(selectUserCompanies);

  const managedGroups = useMemo(
    () => paidolUsers[0].managedGroups?.items.map((item) => item?.cardGroup),
    [paidolUsers]
  );
  const groups = useAppSelector(selectAllGroups);

  const availableGroups = userRole === Role.GROUP_ADMINISTRATOR ? managedGroups : groups;

  useEffect(() => {
    dispatch(getGroups({ paidolId: selectedCompany }));
  }, [dispatch, selectedCompany, open]);

  const handleChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  const onSave = () => {
    onClick(selectedValue);
    setSelectedValue('');
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        color="primary"
        sx={{
          fontSize: '2.4rem',
          mt: '2rem',
          p: '2rem 5rem 1rem',
          alignSelf: 'center',
        }}
      >
        Group Change
      </DialogTitle>
      <DialogContent sx={{ p: '0 5rem' }}>
        <p>Which group do you want to move this card to?</p>

        <FormControl fullWidth>
          <InputLabel id="cardGroupId-label">{t('assignToGroup')}</InputLabel>
          <Select
            id="cardGroupId"
            labelId="cardGroupId-label"
            label={t('assignToGroup')}
            value={selectedValue}
            onChange={handleChange}
            startAdornment={
              <InputAdornment position="start">
                <GroupsIcon />
              </InputAdornment>
            }
          >
            <MenuItem value={NO_GROUP}>{t('noGroup')}</MenuItem>

            {availableGroups
              ?.filter((group): group is CardGroup => !!group)
              .map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <ResponsiveDialogActions sx={{ alignSelf: 'center' }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onSave}
          startIcon={icon}
          loadingPosition="start"
          color="primary"
        >
          {btnText}
        </LoadingButton>
      </ResponsiveDialogActions>
    </ResponsiveDialog>
  );
};

export default AssignGroupModal;
