import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  AgaveAPInvoicesCreateExpenseMutation,
  AgaveAPInvoicesCreateExpenseMutationVariables,
  AgaveAccount,
  AgaveCostCode,
  AgaveCostType,
  AgaveCreateExpenseMutation,
  AgaveCreateExpenseMutationVariables,
  AgaveEmployee,
  AgaveError,
  AgaveGlCode,
  AgaveGlCostType,
  AgaveLinkTokenCreateMutation,
  AgaveLinkTokenExchangeMutation,
  AgaveLinkTokenExchangeMutationVariables,
  AgavePassThroughResponse,
  AgaveProject,
  AgaveVendor,
  CreateAgaveExpenseAPInvoiceInput,
  GetAgaveAccountQuery,
  GetAgaveAccountQueryVariables,
  GetAgaveCostTypesQuery,
  GetAgaveCostTypesQueryVariables,
  GetAgaveEmployeesQuery,
  GetAgaveEmployeesQueryVariables,
  GetAgaveGlCodesQuery,
  GetAgaveGlCodesQueryVariables,
  GetAgaveGlCostTypesQuery,
  GetAgaveGlCostTypesQueryVariables,
  GetAgaveJobsQuery,
  GetAgaveJobsQueryVariables,
  GetAgaveProjectsQuery,
  GetAgaveProjectsQueryVariables,
  GetAgaveVendorByNameQuery,
  GetAgaveVendorByNameQueryVariables,
  GetPaidolQuery,
  PostAgavePassThroughMutation,
  PostAgavePassThroughMutationVariables,
  UpdateHighnoteTransactionMutation,
  UpdateHighnoteTransactionMutationVariables,
  UpdatePaidolInput,
  UpdatePaidolMutation,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import {
  agaveAPInvoicesCreateExpense,
  agaveCreateExpense,
  agaveLinkTokenCreate,
  agaveLinkTokenExchange,
  postAgavePassThrough,
  updatePaidol,
} from 'graphql/mutations';
import {
  getAgaveAccount,
  getAgaveCostTypes,
  getAgaveEmployees,
  getAgaveGlCodes,
  getAgaveGlCostTypes,
  getAgaveJobs,
  getAgaveProjects,
  getAgaveVendorByName,
  getPaidol,
} from 'graphql/queries';
import { updateTransactionAgave } from './mutations';
import { UpdateTransactionArgs } from './transactionsSlice';

export interface AgaveState {
  loading: boolean;
  linkToken: string | undefined;
  accountToken: string | undefined;
  selectedProjectId: string | undefined;
  projects: AgaveProject[] | undefined;
  projectEmployees: AgaveEmployee[] | undefined;
  projectCostCodes: AgaveCostCode[] | undefined;
  costTypes: AgaveCostType[] | undefined;
  agaveAccount: AgaveAccount | undefined;
  glCodes: AgaveGlCode[] | undefined;
  glCostTypes: AgaveGlCostType[] | undefined;
}

const initialState: AgaveState = {
  agaveAccount: undefined,
  loading: false,
  linkToken: undefined,
  accountToken: undefined,
  selectedProjectId: undefined,
  projects: undefined,
  projectEmployees: undefined,
  projectCostCodes: undefined,
  costTypes: undefined,
  glCodes: undefined,
  glCostTypes: undefined,
};

export const getAgaveLinkToken = createAsyncThunk(
  'agave/getAgaveLinkToken',
  async (_, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(agaveLinkTokenCreate)) as Promise<
        GraphQLResult<AgaveLinkTokenCreateMutation>
      >
    )
      .then((result) => {
        return result.data?.agaveLinkTokenCreate?.link_token;
      })
      .catch((result: GraphQLResult<AgaveLinkTokenCreateMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getPersistedAgaveAccountToken = createAsyncThunk(
  'agave/getPersistedAgaveAccountToken',
  async (id: string, { rejectWithValue }) => {
    return (API.graphql(graphqlOperation(getPaidol, { id })) as Promise<GraphQLResult<GetPaidolQuery>>)
      .then((result) => {
        return result.data?.getPaidol?.agaveAccountToken;
      })
      .catch((result: GraphQLResult<GetPaidolQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getAgaveAccountToken = createAsyncThunk(
  'agave/getAgaveAccountToken',
  async ({ input }: AgaveLinkTokenExchangeMutationVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(agaveLinkTokenExchange, { input })) as Promise<
        GraphQLResult<AgaveLinkTokenExchangeMutation>
      >
    )
      .then((result) => {
        return result.data?.agaveLinkTokenExchange?.account_token;
      })
      .catch((result: GraphQLResult<AgaveLinkTokenExchangeMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getProjects = createAsyncThunk(
  'agave/getProjects',
  async (input: GetAgaveProjectsQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveProjects, input)) as Promise<GraphQLResult<GetAgaveProjectsQuery>>
    )
      .then((result) => {
        return result.data?.getAgaveProjects as AgaveProject[];
      })
      .catch((result: GraphQLResult<GetAgaveProjectsQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getGlCodes = createAsyncThunk(
  'agave/getGlCodes',
  async (input: GetAgaveGlCodesQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveGlCodes, input)) as Promise<GraphQLResult<GetAgaveGlCodesQuery>>
    )
      .then((result) => {
        return result.data?.getAgaveGlCodes as AgaveGlCode[];
      })
      .catch((result: GraphQLResult<GetAgaveGlCodesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getAccount = createAsyncThunk(
  'agave/getAgaveAccount',
  async (input: GetAgaveAccountQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveAccount, input)) as Promise<GraphQLResult<GetAgaveAccountQuery>>
    )
      .then((result) => {
        return result.data?.getAgaveAccount as AgaveAccount;
      })
      .catch((result: GraphQLResult<GetAgaveAccountQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getProjectEmployees = createAsyncThunk(
  'agave/getProjectEmployees',
  async (input: GetAgaveEmployeesQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveEmployees, input)) as Promise<
        GraphQLResult<GetAgaveEmployeesQuery>
      >
    )
      .then((result) => {
        return result.data?.getAgaveEmployees as AgaveEmployee[];
      })
      .catch((result: GraphQLResult<GetAgaveEmployeesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getCostCodes = createAsyncThunk(
  'agave/getCostCodes',
  async (input: GetAgaveJobsQueryVariables, { rejectWithValue }) => {
    return (API.graphql(graphqlOperation(getAgaveJobs, input)) as Promise<GraphQLResult<GetAgaveJobsQuery>>)
      .then((result) => {
        return result.data?.getAgaveJobs as AgaveCostCode[];
      })
      .catch((result: GraphQLResult<GetAgaveJobsQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const persistAgaveAccountToken = createAsyncThunk(
  'agave/persistAgaveAccountToken',
  async (input: UpdatePaidolInput, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(updatePaidol, { input })) as Promise<GraphQLResult<UpdatePaidolMutation>>
    )
      .then((result) => result.data?.updatePaidol)
      .catch((result: GraphQLResult<UpdatePaidolMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getCostTypes = createAsyncThunk(
  'agave/getAgaveCostTypes',
  async (input: GetAgaveCostTypesQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveCostTypes, input)) as Promise<
        GraphQLResult<GetAgaveCostTypesQuery>
      >
    )
      .then((result) => result.data?.getAgaveCostTypes as AgaveCostType[])
      .catch((result: GraphQLResult<GetAgaveCostTypesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getGlCostTypes = createAsyncThunk(
  'agave/getAgaveGlCostTypes',
  async (input: GetAgaveGlCostTypesQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveGlCostTypes, input)) as Promise<
        GraphQLResult<GetAgaveGlCostTypesQuery>
      >
    )
      .then((result) => result.data?.getAgaveGlCostTypes as AgaveGlCostType[])
      .catch((result: GraphQLResult<GetAgaveGlCostTypesQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getVendorsByName = createAsyncThunk(
  'agave/getAgaveVendorsByName',
  async (input: GetAgaveVendorByNameQueryVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(getAgaveVendorByName, input)) as Promise<
        GraphQLResult<GetAgaveVendorByNameQuery>
      >
    )
      .then((result) => result.data?.getAgaveVendorByName as AgaveVendor[])
      .catch((result: GraphQLResult<GetAgaveVendorByNameQuery>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createOrUpdateAgaveExpense = createAsyncThunk(
  'agave/createOrUpdateAgaveExpense',
  async (input: AgaveCreateExpenseMutationVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(agaveCreateExpense, input)) as Promise<
        GraphQLResult<AgaveCreateExpenseMutation>
      >
    )
      .then((result) => {
        return result.data?.agaveCreateExpense;
      })
      .catch((result: GraphQLResult<AgaveCreateExpenseMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createAgaveAPInvoice = createAsyncThunk(
  'agave/createAgaveAPInvoice',
  async (input: AgaveAPInvoicesCreateExpenseMutationVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(agaveAPInvoicesCreateExpense, input)) as Promise<
        GraphQLResult<AgaveAPInvoicesCreateExpenseMutation>
      >
    )
      .then((result) => {
        return result.data?.agaveAPInvoicesCreateExpense;
      })
      .catch((result: GraphQLResult<AgaveAPInvoicesCreateExpenseMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createSageIntacctAPInvoice = createAsyncThunk(
  'agave/createSageIntacctAPInvoice',
  async (input: PostAgavePassThroughMutationVariables, { rejectWithValue }) => {
    return (
      API.graphql(graphqlOperation(postAgavePassThrough, input)) as Promise<
        GraphQLResult<PostAgavePassThroughMutation>
      >
    )
      .then((result) => {
        return result.data?.postAgavePassThrough;
      })
      .catch((result: GraphQLResult<PostAgavePassThroughMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const createSageIntacctVendor = createAsyncThunk(
  'agave/createSageIntacctVendor',
  async (input: PostAgavePassThroughMutationVariables, { rejectWithValue }) => {
    const payload = `<create>
      <VENDOR>
        <VENDORID>SPEEDCHAIN</VENDORID>
        <NAME>SPEEDCHAIN</NAME>
        <DISPLAYCONTACT>
          <PRINTAS>SPEEDCHAIN</PRINTAS>
          <COMPANYNAME>SPEEDCHAIN</COMPANYNAME>
          <PREFIX>Mr</PREFIX>
          <FIRSTNAME>Daniel</FIRSTNAME>
          <LASTNAME>Cage</LASTNAME>
          <INITIAL></INITIAL>
          <PHONE1>470-903-2971</PHONE1>
          <EMAIL1>info@speedchain.com</EMAIL1>
          <EMAIL2></EMAIL2>
          <URL1></URL1>
          <URL2></URL2>
          <MAILADDRESS>
            <ADDRESS1>115 Howell Mill Rd NW</ADDRESS1>
            <ADDRESS2>Ste 360</ADDRESS2>
            <CITY>Atlanta</CITY>
            <STATE>GA</STATE>
            <ZIP>30318</ZIP>
            <COUNTRY>United States</COUNTRY>
          </MAILADDRESS>
        </DISPLAYCONTACT>
      </VENDOR>
    </create>`
      .replace(/>\s+</g, '><')
      .trim();
    input.input.data = payload;
    return (
      API.graphql(graphqlOperation(postAgavePassThrough, input)) as Promise<
        GraphQLResult<PostAgavePassThroughMutation>
      >
    )
      .then((result) => {
        if (result.data?.postAgavePassThrough.__typename === 'AgavePassThroughResponse') {
          return (result.data?.postAgavePassThrough as AgavePassThroughResponse)?.body;
        }
        throw new Error((result.data?.postAgavePassThrough as AgaveError)?.message ?? 'Unknown error');
      })
      .catch((result: GraphQLResult<PostAgavePassThroughMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const getSageIntacctVendor = createAsyncThunk(
  'agave/getSageIntacctVendor',
  async (input: PostAgavePassThroughMutationVariables, { rejectWithValue }) => {
    const payload = `<readByName>
      <object>VENDOR</object>
      <keys>SPEEDCHAIN</keys>
      <fields>*</fields>
    </readByName>`
      .replace(/>\s+</g, '><')
      .trim();
    input.input.data = payload;
    return (
      API.graphql(graphqlOperation(postAgavePassThrough, input)) as Promise<
        GraphQLResult<PostAgavePassThroughMutation>
      >
    )
      .then((result) => {
        if (result.data?.postAgavePassThrough.__typename === 'AgavePassThroughResponse') {
          return (result.data?.postAgavePassThrough as AgavePassThroughResponse)?.body;
        }
        throw new Error((result.data?.postAgavePassThrough as AgaveError)?.message ?? 'Unknown error');
      })
      .catch((result: GraphQLResult<PostAgavePassThroughMutation>) => {
        return rejectWithValue(result.errors);
      });
  }
);

export const updateTransactionAgaveClient = createAsyncThunk(
  'overview/transactions/updateTransaction',
  async ({
    id,
    agaveCostType,
    agaveCostCode,
    agaveProject,
    agaveVendor,
    agaveExpense,
    agaveGlCode,
    agaveGlCostType,
  }: UpdateTransactionArgs) => {
    const variables: UpdateHighnoteTransactionMutationVariables = {
      input: {
        transactionId: id,
        ...(agaveCostType !== undefined && {
          agaveCostTypeId: agaveCostType?.id ?? null,
          agaveCostType: agaveCostType,
        }),
        ...(agaveCostCode !== undefined && {
          agaveCostCodeId: agaveCostCode?.id ?? null,
          agaveCostCode: agaveCostCode,
        }),
        ...(agaveProject !== undefined && {
          agaveProjectId: agaveProject?.id ?? null,
          agaveProject: agaveProject,
        }),
        ...(agaveVendor !== undefined && {
          agaveVendorId: agaveVendor?.id ?? null,
          agaveVendor: agaveVendor,
        }),
        ...(agaveExpense !== undefined && {
          agaveExpenseId: agaveExpense?.id ?? null,
          agaveExpense: agaveExpense as CreateAgaveExpenseAPInvoiceInput,
        }),
        ...(agaveGlCode !== undefined && {
          agaveGlCodeId: agaveGlCode?.id ?? null,
          agaveGlCode: agaveGlCode,
        }),
        ...(agaveGlCostType !== undefined && {
          agaveGlCostTypeId: agaveGlCostType?.id ?? null,
          agaveGlCostType: agaveGlCostType,
        }),
      },
    };
    return (
      API.graphql(graphqlOperation(updateTransactionAgave, variables)) as Promise<
        GraphQLResult<UpdateHighnoteTransactionMutation>
      >
    ).then((result) => result?.data?.updateHighnoteTransaction);
  }
);

const agaveSlice = createSlice({
  name: 'agave',
  initialState,
  reducers: {
    resetAgaveSlice: () => initialState,
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(persistAgaveAccountToken.fulfilled, (state, action) => {
      state.accountToken = action.payload?.agaveAccountToken ?? undefined;
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload || [];
    });

    builder.addCase(getProjectEmployees.fulfilled, (state, action) => {
      state.projectEmployees = action.payload || [];
    });

    builder.addCase(getCostCodes.fulfilled, (state, action) => {
      state.projectCostCodes = action.payload || [];
    });

    builder.addCase(getAgaveLinkToken.fulfilled, (state, action) => {
      state.linkToken = action.payload!;
    });

    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.agaveAccount = action.payload;
    });

    builder.addCase(getCostTypes.fulfilled, (state, action) => {
      state.costTypes = action.payload!;
    });

    builder.addCase(getGlCodes.fulfilled, (state, action) => {
      state.glCodes = action.payload || [];
    });

    builder.addMatcher(
      isAnyOf(
        getAgaveLinkToken.pending,
        getAgaveAccountToken.pending,
        getProjects.pending,
        getCostCodes.pending,
        getCostTypes.pending,
        createOrUpdateAgaveExpense.pending,
        getVendorsByName.pending,
        getGlCodes.pending
      ),
      (state) => {
        state.loading = true;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAgaveLinkToken.fulfilled,
        getAgaveAccountToken.fulfilled,
        getProjects.fulfilled,
        getProjects.rejected,
        getProjectEmployees.fulfilled,
        getProjectEmployees.rejected,
        getCostCodes.fulfilled,
        getCostCodes.rejected,
        getCostTypes.rejected,
        getCostTypes.fulfilled,
        createOrUpdateAgaveExpense.fulfilled,
        createOrUpdateAgaveExpense.rejected,
        getVendorsByName.fulfilled,
        getVendorsByName.rejected,
        getGlCodes.fulfilled,
        getGlCodes.rejected
      ),
      (state) => {
        state.loading = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getPersistedAgaveAccountToken.fulfilled, getAgaveAccountToken.fulfilled),
      (state, action) => {
        state.accountToken = action.payload!;
      }
    );
  },
});

export const { resetAgaveSlice, setSelectedProjectId } = agaveSlice.actions;

export const selectAgaveSlice = (state: RootState): AgaveState => state?.cards?.agave ?? initialState;

export default agaveSlice.reducer;
