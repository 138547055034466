import * as Styled from 'app/shared-components/content-component/style';
import PinSDK from 'app/shared-components/PinSDK';
import SpecificCard from 'app/shared-components/cards/SpecificCard';
import LoadingAbsolute from 'app/shared-components/util/LoadingAbsolute';
import SideSheetHeader from 'app/shared-components/sidesheets/SideSheetHeader';
import SideSheetContent from 'app/shared-components/sidesheets/SideSheetContent';
import useSidesheetById from 'app/hooks/useSidesheetById';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { SidesheetRoutes } from 'app/shared-components/sidesheets/SidesheetProvider';
import { selectUserProfile } from 'app/store/userProfileSlice';
import { selectUserCompanies } from 'app/store/userCompaniesSlice';
import { selectReviewOnboardSlice } from 'app/pages/store/reviewOnboardSlice';
import { getHNBusinessAccountHolder } from 'app/pages/onboard/store';
import { useAppDispatch, useAppSelector } from 'app/store';
import { HNPaymentCardClientTokenPermission } from 'API';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  activatePaymentCard,
  generatePaymentCardClientToken,
  getHighnotePaymentCard,
  resetSpecificCardSlice,
  selectSpecificCardSlice,
  setIsCurrentCardActivated,
  updateHighnotePaymentCard,
} from 'app/pages/store/specificCardSlice';
import defaultColors from 'app/theme/defaultColors';
import { captureException } from '@sentry/react';

export interface CardSheetProps {
  open: boolean;
  onClose: (openCardSheet: boolean) => void;
  paymentCardId?: string;
}

function ActivateCardSheet(): JSX.Element {
  const dispatch = useAppDispatch();
  const sidesheet = useSidesheetById(SidesheetRoutes.ActivateCard);
  const params: CardSheetProps = sidesheet.params;

  const { t } = useTranslation();
  const { profile } = useAppSelector(selectUserProfile);
  const { integration } = useAppSelector(selectReviewOnboardSlice);
  const { selectedPaidolId } = useAppSelector(selectUserCompanies);
  const { cardProduct, formFactor } = useAppSelector(selectSpecificCardSlice);

  const [token, setToken] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [loading, setLoading] = useState(true);
  const [isPhysical, setIsPhysical] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const { status } = useAppSelector(selectSpecificCardSlice);

  const isCreditAccount = useMemo(
    () => integration?.highnoteProduct?.type === 'CREDIT',
    [integration?.highnoteProduct?.type]
  );

  const activateCard = async (paymentCardId: string) => {
    if (status === 'ACTIVATION_REQUIRED') {
      await dispatch(activatePaymentCard({ paymentCardId })).unwrap();
    }
  };

  const handleActivateCreditCard = async () => {
    try {
      setBtnLoading(true);
      await activateCard(params.paymentCardId ?? '');
      dispatch(setIsCurrentCardActivated(true));
      params.onClose(true);
    } catch (error) {
      captureException(error, { extra: { paymentCardId: params.paymentCardId } });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleSubmit = async () => {
    dispatch(setIsCurrentCardActivated(true));
    params.onClose(true);
  };

  const loadPaymentCard = useCallback(
    (paymentCardId: string, paidolId: string) =>
      dispatch(
        getHighnotePaymentCard({
          paymentCardId,
          paidolId,
          yearAndMonth: format(new Date(), 'yyyy-MM'),
        })
      )
        .unwrap()
        .then((response) => {
          const paymentCardDetails = response.paymentCardDetails;
          const highnotePaymentCard = response.highnotePaymentCard;

          const cardBelongsToCurrentUser = profile
            ? highnotePaymentCard?.paidolUsers?.items.find(
                (item) => item?.paidolUser?.user?.id === profile.id
              )
            : false;
          if (
            !cardBelongsToCurrentUser ||
            (paymentCardDetails?.status !== 'ACTIVATION_REQUIRED' && highnotePaymentCard?.hasPin)
          ) {
            dispatch(updateHighnotePaymentCard({ paymentCardId, status: paymentCardDetails?.status }));
            dispatch(setIsCurrentCardActivated(true));
            return params.onClose(true);
          }

          if (paymentCardDetails?.formFactor === 'PHYSICAL') {
            setIsPhysical(true);
          }
        }),
    [dispatch, profile, params]
  );

  const loadPaymentCardClientToken = useCallback(
    (paymentCardId: string) =>
      dispatch(
        generatePaymentCardClientToken({
          paymentCardId: paymentCardId,
          permissions: [HNPaymentCardClientTokenPermission.READ_RESTRICTED_DETAILS],
        })
      )
        .unwrap()
        .then((response) => setToken(response)),
    [dispatch]
  );

  const loadAccountBusinessHolder = useCallback(
    (paidolId: string) =>
      dispatch(getHNBusinessAccountHolder(paidolId))
        .unwrap()
        .then((response) => {
          setZipcode(response?.businessProfile?.billingAddress?.postalCode || '');
        }),
    [dispatch]
  );

  useEffect(() => {
    const initialLoad = async () => {
      if (params.paymentCardId && selectedPaidolId) {
        setLoading(true);

        await loadPaymentCard(params.paymentCardId, selectedPaidolId);
        await loadPaymentCardClientToken(params.paymentCardId);
        await loadAccountBusinessHolder(integration?.businessAccountHolderId || '');

        setLoading(false);
      }
    };

    initialLoad();

    return () => {
      dispatch(resetSpecificCardSlice());
    };
  }, [
    dispatch,
    loadPaymentCard,
    loadPaymentCardClientToken,
    params,
    selectedPaidolId,
    integration,
    loadAccountBusinessHolder,
  ]);

  if (loading) {
    return <>{loading && <LoadingAbsolute />}</>;
  }

  return (
    <>
      {btnLoading && <LoadingAbsolute />}
      <SideSheetHeader title={t('activateCard')} onClose={() => params.onClose(false)} />
      <SideSheetContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: { xs: 3, sm: 2 },
            p: 0,
            alignItems: 'center',
            minWidth: { xs: '100%', sm: '650px' }, // Responsive minWidth
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              width: '100%',
              px: 2,
              py: { xs: 3, sm: 5 },
              backgroundColor: defaultColors.lightBlueColor,
            }}
          >
            <SpecificCard
              paymentCardId={params.paymentCardId ?? ''}
              clientToken={token}
              name={cardProduct.name}
              formFactor={formFactor}
              zipcode={zipcode}
              sx={{ height: '275px', width: '100%', maxWidth: '435px' }}
            />
          </Box>

          {/* Activate card */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: isCreditAccount ? '350px' : '100%',
              pt: 3,
              px: 2,
            }}
          >
            <Typography variant="h2" fontWeight="bold" mb={3}>
              {t('hello')} {profile?.first_name},
            </Typography>
            {isCreditAccount ? (
              <>
                <Typography sx={{ pb: 3 }}>
                  {' '}
                  {isPhysical
                    ? t('cardActivation.physicalCardMessageStrongCredit')
                    : t('cardActivation.activateCreditDescription')}
                </Typography>
                <Styled.CustomButton onClick={handleActivateCreditCard} variant="contained">
                  {t('cardActivation.activateCard')}
                </Styled.CustomButton>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  fontWeight="normal"
                  fontSize="16px"
                  lineHeight="24px"
                  id="pin-activation-message"
                  mb={5}
                  sx={{
                    maxWidth: '450px',
                  }}
                >
                  {isPhysical ? (
                    <>
                      {t('cardActivation.physicalCardMessage')} <br />
                      <strong>{t('cardActivation.physicalCardMessageStrong')}</strong>
                    </>
                  ) : (
                    <>{t('createPin')}</>
                  )}
                </Typography>
                <Box sx={{ maxWidth: '450px' }}>
                  <PinSDK
                    isPhysical={isPhysical}
                    paymentCardId={params.paymentCardId ?? ''}
                    onSubmit={handleSubmit}
                    btnLoading={btnLoading}
                    setBtnLoading={setBtnLoading}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </SideSheetContent>
    </>
  );
}

export default ActivateCardSheet;
