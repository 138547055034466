const aws_auth = {
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${process.env.REACT_APP_DOMAIN}/login/sso/`,
    redirectSignOut: `${process.env.REACT_APP_DOMAIN}/logout/`,
    responseType: 'code',
  },
};

export default aws_auth;
export const providerMapping = {
  'speedchaincorp.onmicrosoft.com': 'Speedchain-Entra',
  'theprggroup.com': 'PRG-Entra',
  'prgcommercialmt.com': 'PRG-Entra',
  'unitedconcretemt.com': 'PRG-Entra',
  'mountainstatescivil.com': 'PRG-Entra',
};
