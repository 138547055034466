import { RootState } from 'app/store/rootReducer';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { API, graphqlOperation } from 'aws-amplify';
import { dispatchMessage, sendEmail } from 'graphql/queries';
import { GetHighnotePaymentCardResults } from './paymentCardsSlice';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CreateHighnotePaymentCard, GetPaymentCardDetails, UpdateHighnotePaymentCard } from './queries';
import {
  BudgetSpendByMonthAndPaymentCard,
  CreateHighnotePaymentCardMutation,
  DispatchMessageQuery,
  DispatchMessageQueryVariables,
  HNActivatePaymentCardInput,
  HNAmountLimitSpendRule,
  HNCardFormFactor,
  HNClientToken,
  HNGeneratePaymentCardClientTokenInput,
  HNPaymentCard,
  HNPaymentCardStatus,
  HNVelocitySpendRuleAmountBalance,
  HighnotePaymentCard,
  HnActivatePaymentCardMutation,
  HnClosePaymentCardMutation,
  HnGeneratePaymentCardClientTokenMutation,
  HnSuspendPaymentCardMutation,
  InviteUserMutation,
  InviteUserToPaidol,
  PaidolUserToHighnotePaymentCard,
  RemoveUserFromPaymentCardMutation,
  SendEmailQuery,
  SendEmailQueryVariables,
  UpdateHighnotePaymentCardInput,
  UpdateHighnotePaymentCardMutation,
} from 'API';
import {
  hnActivatePaymentCard,
  hnClosePaymentCard,
  hnGeneratePaymentCardClientToken,
  hnSuspendPaymentCard,
  inviteUser,
  removeUserFromPaymentCard,
} from 'graphql/mutations';

export interface PaymentCardDetails {
  paymentCardDetails?: HNPaymentCard;
  highnotePaymentCard?: HighnotePaymentCard;
  highnotePaymentCardBudget?: BudgetSpendByMonthAndPaymentCard;
}
export interface SpecificCardState {
  formFactor: HNCardFormFactor;
  status: HNPaymentCardStatus | null;
  balance: {
    currentBalance: {
      value: number;
      currencyCode: string;
    };
    remainingBalance: {
      value: number;
      currencyCode: string;
    };
  };
  cardProduct: {
    name: string;
  };
  velocityRule?: HNAmountLimitSpendRule;
  paymentCardDetails?: PaymentCardDetails;
  cardUsers?: Array<PaidolUserToHighnotePaymentCard>;
  updatePending: boolean;
  isCurrentCardActivated: boolean;
}

export const initialState: SpecificCardState = {
  formFactor: HNCardFormFactor['VIRTUAL'],
  balance: {
    currentBalance: {
      value: 0,
      currencyCode: 'USD',
    },
    remainingBalance: {
      value: 0,
      currencyCode: 'USD',
    },
  },
  cardProduct: { name: '' },
  status: null,
  updatePending: false,
  isCurrentCardActivated: false,
};

export const generatePaymentCardClientToken = createAsyncThunk(
  'cards/card/generatePaymentCardClient',
  async ({ paymentCardId, permissions }: HNGeneratePaymentCardClientTokenInput) => {
    const variables = {
      input: {
        paymentCardId: paymentCardId,
        permissions: permissions,
      },
    };

    return (
      API.graphql(graphqlOperation(hnGeneratePaymentCardClientToken, variables)) as Promise<
        GraphQLResult<HnGeneratePaymentCardClientTokenMutation>
      >
    ).then((results) => {
      const data = results.data?.hnGeneratePaymentCardClientToken as HNClientToken;
      return data?.value;
    });
  }
);

export const dispatchSpeedchatMessage = createAsyncThunk(
  'cards/card/dispatchSpeedchatMessage',
  async ({ message, userId, paidolId }: DispatchMessageQueryVariables) => {
    return API.graphql(graphqlOperation(dispatchMessage, { userId, message, paidolId })) as Promise<
      GraphQLResult<DispatchMessageQuery>
    >;
  }
);

export const dispatchEmail = createAsyncThunk(
  'cards/card/dispatchEmail',
  async ({ body, subject, to }: SendEmailQueryVariables) => {
    return API.graphql(graphqlOperation(sendEmail, { body, subject, to })) as Promise<
      GraphQLResult<SendEmailQuery>
    >;
  }
);

export const activatePaymentCard = createAsyncThunk(
  'cards/card/activatePaymentCard',
  async ({ paymentCardId }: HNActivatePaymentCardInput, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(hnActivatePaymentCard, {
          input: {
            paymentCardId,
          },
        })
      ) as Promise<GraphQLResult<HnActivatePaymentCardMutation>>
    ).then((result) => {
      const paymentCard = result?.data?.hnActivatePaymentCard as HNPaymentCard;
      if (paymentCard.status && paymentCard.status === HNPaymentCardStatus.ACTIVE) {
        dispatch(updateHighnotePaymentCard({ paymentCardId, status: paymentCard.status }));
      }
      return paymentCard;
    });
  }
);

export const suspendPaymentCard = createAsyncThunk(
  'cards/card/suspendPaymentCard',
  async ({ paymentCardId }: HNActivatePaymentCardInput) => {
    return (
      API.graphql(
        graphqlOperation(hnSuspendPaymentCard, {
          input: {
            paymentCardId,
          },
        })
      ) as Promise<GraphQLResult<HnSuspendPaymentCardMutation>>
    ).then((result) => result?.data?.hnSuspendPaymentCard as HNPaymentCard);
  }
);

export const closePaymentCard = createAsyncThunk(
  'cards/card/closePaymentCard',
  async ({ paymentCardId }: HNActivatePaymentCardInput) => {
    return (
      API.graphql(
        graphqlOperation(hnClosePaymentCard, {
          input: {
            paymentCardId,
          },
        })
      ) as Promise<GraphQLResult<HnClosePaymentCardMutation>>
    ).then((result) => {
      return result?.data?.hnClosePaymentCard as HNPaymentCard;
    });
  }
);

export interface GetHighnotePaymentCardArgs {
  paymentCardId: string;
  paidolId: string;
  yearAndMonth: string;
}

export const getHighnotePaymentCard = createAsyncThunk<PaymentCardDetails, GetHighnotePaymentCardArgs>(
  'cards/card/getHighnotePaymentCard',
  async ({ paymentCardId, paidolId, yearAndMonth }: GetHighnotePaymentCardArgs) => {
    return (
      API.graphql(
        graphqlOperation(GetPaymentCardDetails, {
          id: paymentCardId,
          paidolId,
          yearAndMonth,
        })
      ) as Promise<GraphQLResult<GetHighnotePaymentCardResults>>
    ).then(async (result) => {
      const response: PaymentCardDetails = {
        paymentCardDetails: result?.data?.hnGetPaymentCardDetails,
        highnotePaymentCard: result?.data?.getHighnotePaymentCard,
        highnotePaymentCardBudget: result?.data?.getBudgetSpendByMonthAndPaymentCard,
      };

      if (response.highnotePaymentCard) {
        return response;
      }

      return (
        API.graphql(
          graphqlOperation(CreateHighnotePaymentCard, {
            input: {
              paymentCardId,
              paidolId,
              authPaidolId: paidolId,
            },
          })
        ) as Promise<GraphQLResult<CreateHighnotePaymentCardMutation>>
      ).then((results) => {
        response.highnotePaymentCard = results.data?.createHighnotePaymentCard as HighnotePaymentCard;

        return response;
      });
    });
  }
);

export const updateHighnotePaymentCard = createAsyncThunk(
  'cards/card/updateHighnotePaymentCard',
  async (input: UpdateHighnotePaymentCardInput) => {
    return (
      API.graphql(graphqlOperation(UpdateHighnotePaymentCard, { input })) as Promise<
        GraphQLResult<UpdateHighnotePaymentCardMutation>
      >
    ).then((result) => result?.data?.updateHighnotePaymentCard as HighnotePaymentCard);
  }
);

interface AssignPaymentCardArgs {
  input: InviteUserToPaidol;
  highnotePaymentCardId: string;
}

export const assignPaymentCard = createAsyncThunk(
  'cards/paymentCards/assignPaymentCard',
  async ({ input, highnotePaymentCardId }: AssignPaymentCardArgs) => {
    let hostname = window.location.origin;
    if (!window.location.origin) {
      hostname =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }
    return (
      API.graphql(graphqlOperation(inviteUser, { input, highnotePaymentCardId, hostname })) as Promise<
        GraphQLResult<InviteUserMutation>
      >
    ).then((results) => results?.data?.inviteUser);
  }
);

export const unassignPaymentCard = createAsyncThunk(
  'cards/card/unassignPaymentCard',
  async (paidolUserToHighnotePaymentCardId: string) => {
    return (
      API.graphql(
        graphqlOperation(removeUserFromPaymentCard, {
          paidolUserToHighnotePaymentCardId,
        })
      ) as Promise<GraphQLResult<RemoveUserFromPaymentCardMutation>>
    ).then((result) => result?.data?.removeUserFromPaymentCard);
  }
);

const specificCardSlice = createSlice({
  name: 'cards/card',
  initialState,
  reducers: {
    resetSpecificCardSlice: () => initialState,
    setIsCurrentCardActivated: (state, action) => {
      state.isCurrentCardActivated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateHighnotePaymentCard.pending, (state) => {
      state.updatePending = true;
    });
    builder.addCase(getHighnotePaymentCard.fulfilled, (state, action) => {
      const paymentCard = action.payload.paymentCardDetails as HNPaymentCard | undefined;

      const budget = action.payload.highnotePaymentCardBudget as BudgetSpendByMonthAndPaymentCard | undefined;

      const velocityRule = paymentCard?.attachedVelocityRules?.edges?.at(0)?.node?.cumulativeRule as
        | HNAmountLimitSpendRule
        | undefined;

      const velocityBalance = paymentCard?.velocitySpendRuleBalances?.at(0) as
        | HNVelocitySpendRuleAmountBalance
        | undefined;

      state.formFactor = paymentCard?.formFactor ?? initialState.formFactor;
      state.status = paymentCard?.status ?? initialState.status;
      state.cardProduct = paymentCard?.cardProduct?.name
        ? { name: paymentCard.cardProduct.name }
        : initialState.cardProduct;
      state.velocityRule = velocityRule ?? undefined;

      if (velocityRule && velocityBalance) {
        const { currentBalance, remainingBalance } = velocityBalance;

        state.balance = {
          currentBalance: {
            currencyCode: currentBalance?.currencyCode || 'USD',
            value: currentBalance?.value || 0,
          },
          remainingBalance: {
            currencyCode: remainingBalance?.currencyCode || 'USD',
            value: remainingBalance?.value || 0,
          },
        };
      } else if (budget) {
        state.balance = {
          currentBalance: { currencyCode: 'USD', value: budget.amount || 0 },
          remainingBalance: {
            currencyCode: 'USD',
            value: velocityBalance?.remainingBalance?.value || 0,
          },
        };
      } else {
        state.balance = initialState.balance;
      }

      state.paymentCardDetails = action.payload;

      state.cardUsers = action.payload.highnotePaymentCard?.paidolUsers?.items
        .filter((i) => isNotNullOrUndefined(i))
        .map((i) => i!);
    });
    builder.addCase(activatePaymentCard.pending, (state) => {
      state.updatePending = true;
    });

    builder.addCase(activatePaymentCard.fulfilled, (state, action) => {
      state.status = action.payload?.status ?? initialState.status;
      state.updatePending = false;
    });
    builder.addCase(closePaymentCard.fulfilled, (state, action) => {
      state.status = action.payload?.status ?? initialState.status;
    });
    builder.addCase(suspendPaymentCard.fulfilled, (state, action) => {
      state.status = action.payload?.status ?? initialState.status;
    });
    builder.addCase(updateHighnotePaymentCard.fulfilled, (state, action) => {
      if (action.payload) {
        state.cardUsers = action.payload.paidolUsers?.items
          .filter((i) => isNotNullOrUndefined(i))
          .map((i) => i!);
      }
    });

    builder.addMatcher(
      isAnyOf(updateHighnotePaymentCard.fulfilled, updateHighnotePaymentCard.rejected),
      (state) => {
        state.updatePending = false;
      }
    );
  },
});

export const { resetSpecificCardSlice, setIsCurrentCardActivated } = specificCardSlice.actions;

export const selectSpecificCardSlice = (state: RootState): SpecificCardState =>
  state?.cards?.specificCard ?? initialState;

export default specificCardSlice.reducer;
