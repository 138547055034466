import ResponsiveDialog from 'app/shared-components/layout/dialog/ResponsiveDialog';
import InputAdornment from '@mui/material/InputAdornment';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import { motion } from 'framer-motion';
import { trimText } from 'util/sanitizeText';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { darken, styled } from '@mui/material/styles';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DialogContent, DialogContentText, DialogTitle, Icon, Box } from '@mui/material';
import { providerMapping } from 'aws-auth';
import * as yup from 'yup';

const Root = styled('div')(({ theme }) => ({
  '& .Login3-leftSection': {},

  '& .Login3-rightSection': {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
      theme.palette.primary.dark,
      0.5
    )} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function LoginSSO() {
  const { enqueueSnackbar } = useSnackbar();
  const [isPending, setIsPending] = useState(false);
  const [supportAlertOpen, setSupportAlertOpen] = useState(false);
  const { t } = useTranslation();

  /**
   * Form Validation Schema
   */

  const schema = yup.object().shape({
    email: yup.string().email(t('enterValidEmail')).required(t('enterEmail')),
  });

  const defaultValues = {
    email: '',
  };

  const { control, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(model) {
    setIsPending(true);
    const provider = model.email.split('@')[1];
    const federatedProvider = providerMapping[provider];
    if (!federatedProvider) {
      enqueueSnackbar(t('enterValidEmail'), { variant: 'error' });
      setIsPending(false);
    } else {
      Auth.federatedSignIn({ provider: federatedProvider }).then(() => {
        enqueueSnackbar(`Redirecting...`, { variant: 'info' });
      });
      setIsPending(false);
    }
  }

  return (
    <Root className="flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Login3-leftSection flex flex-col w-full max-w-sm items-center justify-center shadow-0"
          square
        >
          <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
              <div className="flex items-center mb-48">
                <img className="logo-icon w-216" src="assets/images/logos/new_logo.svg" alt="logo" />
              </div>
            </motion.div>

            <form
              name="loginForm"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box sx={{ height: '8rem' }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-16"
                      label={t('email')}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      type="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      onBlur={(e) => field.onChange(trimText(e.target.value))}
                      required
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pl: 1.5 }}>
                            <Icon className="text-20" color="action">
                              person
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                className="w-full mx-auto my-16"
                aria-label="LOG IN"
                disabled={isPending}
                type="submit"
              >
                {isPending ? t('loggingIn') : t('loginSSO')}
              </Button>
            </form>

            <div className="flex flex-col my-10 flex items-center justify-center">
              <Link className="font-normal" to="/onboard/invitation">
                {t('haveInviteCode')}
              </Link>
            </div>

            <div className="flex flex-col my-10 flex items-center justify-center">
              <Link
                className="font-normal"
                to="#"
                onClick={(e) => {
                  setSupportAlertOpen(true);
                  e.preventDefault();
                }}
              >
                {t('needHelp')}
              </Link>
            </div>

            <ResponsiveDialog open={supportAlertOpen} onClose={() => setSupportAlertOpen(false)}>
              <DialogTitle>{t('needHelp')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('contactUsAt')}{' '}
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = 'mailto:support@speedchain.com';
                      e.preventDefault();
                    }}
                  >
                    support@speedchain.com
                  </Link>
                </DialogContentText>
              </DialogContent>
              <Button
                className="mr-16 mb-16"
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => setSupportAlertOpen(false)}
              >
                OK
              </Button>
            </ResponsiveDialog>
          </CardContent>
        </Card>

        <div className="Login3-rightSection flex hidden md:flex flex-1 items-center justify-center p-64">
          <div className="max-w-320">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <Typography color="inherit" className="text-32 sm:text-44 font-semibold leading-tight">
                <Trans i18nKey={'welcomeToSpeedChain'} />
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            ></motion.div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
}

export default LoginSSO;
