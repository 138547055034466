import { Action, CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';
import type { OverviewState } from 'app/pages/overview/store';
import type { PayablesState } from 'app/pages/payables/store';
import type { CardsState } from 'app/pages/store';
import flexCoding, { FlexCodingState } from 'app/pages/store/flexCodingSlice';
import type { TeamState } from 'app/pages/team/store';
import type { AuthState } from 'app/store/authSlice';
import auth from 'app/store/authSlice';
import type { DialogState } from 'app/store/dialogSlice';
import dialog from 'app/store/dialogSlice';
import type { FeatureFlagsState } from 'app/store/featureFlagsSlice';
import featureFlags from 'app/store/featureFlagsSlice';
import type { NavigationState } from 'app/store/navigationSlice';
import navigation from 'app/store/navigationSlice';
import { OnboardState } from 'app/store/onboardSlice';
import type { SearchAndFilterState } from 'app/store/searchAndFilterSlice';
import searchAndFilter from 'app/store/searchAndFilterSlice';
import type { UserCompaniesState } from 'app/store/userCompaniesSlice';
import userCompanies from 'app/store/userCompaniesSlice';
import type { UserProfileState } from 'app/store/userProfileSlice';
import userProfile from 'app/store/userProfileSlice';
import { WalletState } from './walletSlice';

export interface RootState {
  // Static reducers; these are always loaded.
  // These must match the reducers loaded in createReducer() below.
  featureFlags: FeatureFlagsState;
  auth: AuthState;
  userProfile: UserProfileState;
  userCompanies: UserCompaniesState;
  navigation: NavigationState;
  dialog: DialogState;
  searchAndFilter: SearchAndFilterState;
  flexCoding: FlexCodingState;

  // Dynamic reducers; these are loaded at runtime.
  // Keys must be optional.
  // Replace `unknown` with actual types as reducers are ported to TS.
  onboard?: OnboardState;
  overview?: OverviewState;
  payables?: PayablesState;
  partners?: unknown;
  team?: TeamState;
  cards?: CardsState;
  wallet?: WalletState;
}

const createReducer =
  (asyncReducers?: Record<string, Reducer>) =>
  (state: CombinedState<RootState> | undefined, action: Action) => {
    const combinedReducer = combineReducers<RootState>({
      featureFlags,
      auth,
      userProfile,
      userCompanies,
      navigation,
      dialog,
      searchAndFilter,
      flexCoding,
      ...asyncReducers,
    });

    return combinedReducer(state, action);
  };

export default createReducer;
