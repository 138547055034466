import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import Link from '@mui/material/Link';
import { useAppDispatch, useAppSelector } from 'app/store';
import { useAuth } from 'app/shared-components/auth/AuthProvider';
import { selectUserProfile } from 'app/store/userProfileSlice';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { List, ListItemButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function UserMenu() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { profile, picture } = useAppSelector(selectUserProfile);
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const userMenuClose = () => {
    setMenuOpen(false);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Button
        ref={buttonRef}
        id="user-menu-btn"
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          {profile && (
            <Typography variant="large" color="white" sx={{ mr: 1 }}>
              {profile.first_name} {profile.last_name}
            </Typography>
          )}
        </div>

        <Avatar
          className="md:mx-4"
          alt="user photo"
          src={picture !== '' ? picture : 'assets/images/avatars/profile.jpg'}
          sx={{ width: 32, height: 32 }}
        />
      </Button>

      <Popover
        open={menuOpen}
        anchorEl={buttonRef.current}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <List>
          <ListItemButton component={Link} href="/profile" onClick={userMenuClose} role="button">
            <ListItemIcon className="min-w-40">
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('myProfile')} />
          </ListItemButton>
          <ListItemButton
            id="logout-opt-btn"
            onClick={() => {
              navigate('/logout/');
            }}
          >
            <ListItemIcon className="min-w-40">
              <ExitToAppOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('logout')} />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}

export default UserMenu;
